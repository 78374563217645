import React, { memo, useEffect, useState } from 'react';
import { Box, Text, Heading, Menu, Button, Divider, ScrollView, Container, Icon, Modal, FlatList, Skeleton, useMediaQuery, Center, Spinner  } from 'native-base';
import { MaterialIcons } from "@expo/vector-icons";
import { Dimensions, TouchableHighlight, LogBox } from 'react-native';

/*Lodash */
import { sortBy } from 'lodash';

/* Store */
import { useSelector, useDispatch } from 'react-redux'
import { updateSortBy } from '../../../store/actions/appState';

/*GrapghQL*/
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_CATEGORY_FILTER_FACETS } from "../../../apis/graphql";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../../../utils/styles";
import { camelToCapitalize, ctSortOptions, percentage } from '../../../utils/commonFunctions';

const FiltersCT = ({categoryId,facets, appliedFilters, onApplyFilterClick, onClearFilterClick, filterRefreshed}) => {
    const [ getFiltersData, { data }] = useLazyQuery(QUERY_GET_CATEGORY_FILTER_FACETS, { fetchPolicy: "cache-and-network",  nextFetchPolicy: "cache-first" });
    const [relevantProductTypes, setRelevantProductTypes] = useState(null)
    const [filters, setFilterFacets] = useState(null)
    const [ isActiveTab, setActiveTab] = useState();
    const [filterSelectionValue, setFilterSelectionValue] = useState([])
    const [selectedSort, setSelectedSort] = useState("")
    const [closing, setClosing] = useState(false)
    const [windowHeight, setWindowHeight] = useState(null)
    const [showSpinner, setShowSpinner] = useState(true);
    const [isMediumScreen] = useMediaQuery({ maxWidth:767 });

    const allProductTypes = useSelector(state => state.appData.productTypes)
    const showFilterCount = useSelector(state => state.appData.viewConfig.showFilterCount)
    const sortValue = useSelector(state => state.appData.sortBy)
    const dispatch = useDispatch()

    useEffect(()=>setSelectedSort(sortValue),[sortValue])

    useEffect(()=> {
        LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);
        let filterWindowHeight = Dimensions.get('window').width>768? percentage(Dimensions.get('window').height, 80): Dimensions.get('window').height;
        setWindowHeight((filterWindowHeight-65))

        return () => {
            setShowSpinner(true)
            setWindowHeight(null)
            setClosing(false)
        };
    },[])

    const getTagName = (name) =>{
        let rawName= name.split('variants.attributes.')[1]
        return camelToCapitalize(relevantProductTypes.filter(item => item.name === rawName)[0]?.label || rawName)
    }

    const onHandleChangeFilters = (filterName,groupKey) =>{
        let filterNameValue = `${groupKey}:${filterName}`;
        let checkitem = filterSelectionValue.filter(item => item == filterNameValue)
        if(checkitem.length == 0){
            setFilterSelectionValue([ ...filterSelectionValue, filterNameValue])
        }
        else{
            setFilterSelectionValue(filterSelectionValue.filter(item => item !==filterNameValue))
        }
      }

    const getCheckedStatus = (filterName,groupKey) => {
        let filterNameValue = `${groupKey}:${filterName}`;
        let checkitem = filterSelectionValue.filter(item => item == filterNameValue)
        return checkitem.length !== 0;
    }

    const applyFilters = () =>{
        setShowSpinner(true)
        setClosing(true)
        dispatch(updateSortBy(selectedSort))
        onApplyFilterClick(filterSelectionValue,selectedSort)
    }

    const clearFilters = () => {
        setShowSpinner(true)
        setClosing(true)
        onClearFilterClick()
        dispatch(updateSortBy(""))
      }

    const updateSortValue = (value) => { 
        setSelectedSort(value)
    }

    useEffect(() => {
        let facetTermsArray = []
        let productTypesArray = []
        facets?.forEach( item => {
            item.value.terms.forEach(subiitem=>{
                facetTermsArray.push(subiitem.term)
            })
        });

        facetTermsArray.forEach(item => {
            let filteredItem = allProductTypes?.filter(subiitem=>subiitem?.id==item);
            if(filteredItem.length>0){
                productTypesArray = [...productTypesArray, ...filteredItem[0].attributeDefinitions.results]
            }
        })

        setRelevantProductTypes(productTypesArray)

        let productFacetsArray = []
        productTypesArray.forEach(item => {
            let facetModel = {model: { terms: { path: `variants.attributes.${item.name}`, countProducts: true} }}
            productFacetsArray.push(facetModel)
        })

        if(productFacetsArray.length>0){
            getFiltersData({ variables: {  categoryId: categoryId, facetQueries: productFacetsArray }})
        }
      }, [allProductTypes])

    useEffect(() => {
        if(data?.productProjectionSearch?.facets){
            let filterFacets = data?.productProjectionSearch?.facets
            var filteredArray = sortBy(filterFacets, [filter => filter.facet.toLowerCase()])
            let sortedFacets = []
            filteredArray.forEach(item => {
                let sortedItem = JSON.parse(JSON.stringify(item));
                sortedItem.value.terms = sortBy(sortedItem.value.terms, [filter => filter.term.toLowerCase()])
                if(sortedItem.value.terms.length>0){
                    sortedFacets.push(sortedItem)
                }
            });
            setFilterFacets(sortedFacets)
            setActiveTab(sortedFacets[0]?.facet)
        }
      }, [data])

    useEffect(()=>{
        if(filters && filterRefreshed && !closing){
            setFilterSelectionValue(appliedFilters)
        }
        if(filters && !closing){
            setShowSpinner(false)
        }
    },[filters,filterRefreshed])

    const renderItem = ({ item }) => (
        <>
        {item.facet === isActiveTab && <Box key={item.facet} p={2} style={item.facet !== isActiveTab? styles.displayHidden :  styles.displayFlex}>
            {item.facet === isActiveTab && 
                <Box
                    mt="2"
                    alignItems="flex-start"
                    flexWrap={'wrap'}
                    flexDirection={{base:"column", sm:"row"}}
                    minWidth="100%"
                >
                    {
                    item?.value?.terms?.map( (subitem,key) => {
                        return <TouchableHighlight underlayColor="none" key={`sub-${key+subitem.term}`} onPress={()=>{onHandleChangeFilters(subitem.term,item.facet)}}>
                            <Box style={styles.customCheckbox}>
                                { getCheckedStatus(subitem.term,item.facet)?<Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box" />}/>:
                                <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box-outline-blank" />}/>}
                                <Text isTruncated maxWidth={showFilterCount?"75%":"100%"} pl={2}>{subitem.term}</Text>
                                {showFilterCount && <Text flex={1} pl={2}>({ subitem?.productCount})</Text>}
                            </Box>
                        </TouchableHighlight >
                    })
                }
                </Box>
            }
        </Box>
        }</>
    )

    return (
        <>
            <Modal.Body flex={1} height="100%" p={0}>
                <Box style={ styles.filterUiBody}>
                    { showSpinner && <Center flex={1} height={windowHeight}>Please wait...<Spinner size="sm" accessibilityLabel="Loading..." /></Center> }
                    
                    { !showSpinner && <>
                        <Container height={windowHeight} style={ styles.filterUiTabs }>
                            <ScrollView w="100%" p={3}>
                                {isMediumScreen && ctSortOptions && 
                                <Box flex={1} width="100%">
                                    <Heading size="md" mb={2}>Sort By</Heading> 
                                    <Menu
                                    trigger={(triggerProps) => {
                                        return (
                                        <Button variant="outine" w="100%" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} borderWidth="1px" borderColor="gray.100" {...triggerProps} size="sm" pl={3} {...triggerProps} rightIcon={<Icon as={<MaterialIcons name="arrow-downward" />} size="5" />}>{selectedSort?ctSortOptions.filter(item=> item.value ===selectedSort)[0]?.label:"SORT BY"}</Button>
                                        )
                                    }}
                                    >
                                    <Menu.OptionGroup title="Sort By" type="radio" value={selectedSort} onChange={(val) => {updateSortValue(val) }}>
                                        { ctSortOptions.map( (item,key) => (<Menu.ItemOption key={key} onPress={()=>updateSortValue(item.value)} value={item.value}>{item?.label}</Menu.ItemOption>))}
                                    </Menu.OptionGroup>
                                    </Menu>
                                    
                                    <Divider mt={5} mb={5}/>
                                </Box>
                                }

                                <Heading size="md" mb={2}>Filter By</Heading>

                                {/* { !filters && Array(5).fill(0).map((_, i)=> <Skeleton key={i} variant="text" mb={2} height={35} />)} */}

                                {(!filters || filters?.length <= 0) && <Text>Filters not available</Text>}

                                {filters && filters?.map( (item,key) => {return(
                                    <Box mb={1} key={key+item.facet}>
                                        <TouchableHighlight underlayColor={brandTheme.light}
                                            onPress={()=>{setActiveTab(item.facet);}}
                                            style={ item.facet === isActiveTab? styles.activeFiltertab :  ''}
                                        >
                                        <Box style={ styles.customButtonUi } >{ getTagName(item.facet)} <Icon as={<MaterialIcons name="arrow-right" />} size="sm"/></Box>
                                    </TouchableHighlight></Box>)
                                    })
                                }
                            </ScrollView>
                        </Container>
                        <Container style={ styles.filterUiContainer} height={windowHeight}>
                            {/* {!filters && <Box mt={2} ml={2}>{ Array(15).fill(0).map((_, i)=> <Skeleton key={i} variant="text" style={styles.customCheckbox} width="100%" height={25} />)}</Box>} */}
                            
                            {filters && filters.length === 0 && <Text>Filters not available</Text>}
                            
                            {filters && <FlatList  height={windowHeight} w="100%"
                            data={filters}
                            removeClippedSubviews
                            scrollEnabled={false}
                            renderItem={renderItem}
                            keyExtractor={(item) => item.facet}
                            />}
                        </Container>
                    </>}
                </Box>
            </Modal.Body>

            <Modal.Footer p={0} m={0}>
                <Button.Group space={2} style={ styles.filterUiFooter }>
                    <Button colorScheme="coolGray" size="lg" variant="ghost" onPress={clearFilters}>CLEAR ALL</Button>
                    <Button colorScheme="danger" size="lg" onPress={ applyFilters }>APPLY</Button>
                </Button.Group>
            </Modal.Footer>
        </>
    );
}

export default memo(FiltersCT);