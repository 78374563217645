import React, { memo } from 'react';
import { Button, Divider, Center, Heading,  VStack, ScrollView, SimpleGrid, IconButton, Icon, Box } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

/*Common Functions & Styles*/
import { brandTheme } from '../../utils/styles';

const HamburgerMenu = ({navigation,categories,updateMenuToggleState}) => {
    const onNavigateMenu = (category) =>{
        updateMenuToggleState(true)
        navigation.push("product-category", { category: category.slug, id: category.id })
    }

    const onMenuToggleFunction = () => {
        updateMenuToggleState(true)
    }
      
  return (
    <Box safeAreaTop style={{position:"relative", width:"100%", height:"100%", right:0 }}>
        <Center style={{height:"100%",backgroundColor: brandTheme.light }}>
            <IconButton size="lg" variant="ghost" w="70px" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} alignItems="center" zIndex="1" position="absolute" top="5px" right="5px" onPress={()=>onMenuToggleFunction()}
                icon={<Icon size="xl" color={ brandTheme.inputIcon } as={<MaterialIcons name="close" />}
                />}
            />
            <ScrollView pt={2} pb={4} width="100%" height="100%" >
                <SimpleGrid  pt="5%" alignItems="flex-start" flexDirection={{ base: "column", md: "row" }} minChildWidth={10} spacing={3}>
                    <VStack alignItems="flex-start" space={0} px="2">
                        <Heading size="md" p="3">Categories</Heading>
                        <Divider></Divider>
                        {categories?.results?.map((category, key) => {
                            return (
                                <Button rounded="0" size="md" variant="ghost" key={key} onPress={() => onNavigateMenu(category)}>{category.name}</Button>
                            );
                        })}
                    </VStack>
                    <VStack alignItems="flex-start" space={0} px="2">
                        <Heading size="md" p="3">Other Links</Heading>
                        <Divider></Divider>
                        <Button size="md" variant="ghost" onPress={() => alert('clicked') }>Shopping &amp; Returns</Button>
                        <Button size="md" variant="ghost" onPress={() => alert('clicked') }>Contact Us</Button>
                        <Button size="md" variant="ghost" onPress={() => alert('clicked') }>About Us</Button>
                        <Button size="md" variant="ghost" onPress={() => alert('clicked') }>My Account</Button>
                    </VStack>
                </SimpleGrid>
            </ScrollView>
        </Center>
    </Box>
  );
}

export default memo(HamburgerMenu);