import React, { memo } from 'react';
import { HStack, IconButton, Icon, Box, Image } from "native-base";
import { Pressable } from "react-native"
import { MaterialIcons } from "@expo/vector-icons";

/*Common Functions & Styles*/
import { brandTheme } from "../../utils/styles";

/* Import Logos */
import LogoMobileImage from "../../../assets/NvizionSolutions-Logo-icon.png"

/* import sub componenets */
import SearchBox from './SearchBox';

const MobilieStickyHeader = ({ navigation, route, isMediumScreen, colorMode }) => {
  const handleBack = () => {
    if(navigation.canGoBack()){
      navigation.pop()
    }else{
      navigation.navigate("home")
    }
  }

  return (
    <HStack bg={brandTheme.white} alignItems="center" px={2} py={3} justifyContent="space-between" borderBottomWidth="1px" borderBottomColor={colorMode+'.200'}>
        {route.name !== "home" && (
            <IconButton flex={1} size="md" alignItems="center"
              onPress={() => handleBack()}
              _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
              icon={<Icon  as={<MaterialIcons name="arrow-back" />} width="20" color={brandTheme.brand} />}
            />
        )}

        {route.name === "home" && (
        <Pressable variant="ghost" style={{ height: 50, width: 50 }} alignItems="center" onPress={() => navigation.navigate("home")}><Image
            style={{ height: 50, width: 50 }}
            source={LogoMobileImage}
            alt="Logo"
            resizeMode="contain"
          />
        </Pressable>
          )}

        <Box width="85%">
          <SearchBox navigation={navigation} isMediumScreen={isMediumScreen} colorMode={colorMode}/>
        </Box>
    </HStack>
  );
}

export default memo(MobilieStickyHeader);
