/* Default Imports */
import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { extendTheme, NativeBaseProvider } from "native-base";

/*Common Functions & Styles*/
import { screenOptions, brandTheme } from "./utils/styles";
import { getName } from "./utils/commonFunctions";

/* Components */
import Home from "./pages/HomePage";
import ProductCategoryListing from "./pages/ProductCategoryListingPage";
import ProductDetail from "./pages/ProductDetailPage";
import ProductDetailSQL from "./pages/ProductDetailPageSQL";
import ProductCategoryListingPageSQL from "./pages/ProductCategoryListingPageSQL";

/* Default Variables */
const theme = extendTheme(brandTheme);
const Stack = createNativeStackNavigator();
const linking = {};

const Root = () => {
  return (
    <NativeBaseProvider theme={theme}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator initialRouteName="home" screenOptions={screenOptions}>
          <Stack.Screen
            name="home"
            component={Home}
            options={{
              title: 'Nviz Store | Home',
              backgroundColor: "#fff"
            }}
          />
          <Stack.Screen
            name="product-category"
            component={ProductCategoryListing}
            options={({ route }) => ({ 
              title: `${getName(route.name)} ${(route?.params?.category)? '| ' + getName(route?.params?.category) :''}`
            })}
          />
          <Stack.Screen
            name="product-category-ipc"
            component={ProductCategoryListingPageSQL}
            options={({ route }) => ({ 
              title: `${getName(route.name)} ${(route?.params?.category)? '| ' + getName(route?.params?.category) :''}`
            })}
          />
          <Stack.Screen
            name="product-detail"
            component={ProductDetail}
            options={({ route }) => ({ 
              title: `${getName(route.name)} | ${getName(route.params.category)}`
            })}
          />
          <Stack.Screen
            name="product-detail-ipc"
            component={ProductDetailSQL}
            options={({ route }) => ({ 
              title: `${getName(route.name)} | ${getName(route.params.category)}`
            })}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}

export default Root;