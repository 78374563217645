import { StyleSheet, Platform } from "react-native";
import styled from "styled-components";

export const WHITE = "#fff";
export const LIGHT = "#eeeeee";
export const GRAY = "#666666";
export const BLUE = "#00a8e6";
export const ACTIVE = "#00a8e6";
export const MENUCOLOR = "#000";
export const BLACK = "#000";
export const INPUTBORDER = "#ccc";

export const brandTheme = {
  light: LIGHT,
  brand: BLUE,
  white: WHITE,
  dark: BLACK,
  gray: GRAY,
  brandColor: BLUE,
  whiteColor: WHITE,
  menuColor: MENUCOLOR,
  inputBorderFocus: BLACK,
  inputIcon: BLACK,
  bannerButtonBg: "#ff0",
  bannerButtonHoverBg: "#666",
  bg:"#fff",
  colors: {
    "primary.100": 'rgba(0, 168, 230, 0.1)',
    "primary.200": 'rgba(0, 168, 230, 0.3)',
    "primary.300": 'rgba(0, 168, 230, 0.6)',
    "primary.400": 'rgba(0, 168, 230, 0.8)',
    "primary.500": 'rgba(0, 168, 230, 1.0)',
    "primary.600": 'rgba(1, 88, 120, 0.7)',
    "primary.700": 'rgba(1, 88, 120, 0.8)',
    "primary.800": 'rgba(1, 88, 120, 0.9)',
    "primary.900": 'rgba(1, 88, 120, 1.0)',
    "default.100": 'rgba(0, 168, 230, 0.1)',
    "default.200": 'rgba(0, 0, 0, 1.0)',
    "default.300": 'rgba(0, 0, 0, 0.3)',
    "default.400": 'rgba(0, 0, 0, 0.4)',
    "default.500": 'rgba(0, 0, 0, 1)',
    "default.600": 'rgba(0, 0, 0, 0.6)',
    "default.700": 'rgba(0, 0, 0, 0.7)',
    "default.800": 'rgba(0, 0, 0, 0.8)',
    "default.900": 'rgba(0, 0, 0, 0.9)',
  },
  defaultHover: { bg: LIGHT, _text: {color: BLUE}, borderColor: LIGHT },
  defaultPressed: { bg: LIGHT, borderColor: LIGHT },
  menuHover: { bg: BLUE, _text: {color: WHITE}, borderColor: BLUE },
  menuPressed: { bg: BLUE, _text: {color: WHITE}, borderColor: LIGHT },
  components: {
      Button: {
          baseStyle: {
            backgroundColor: "#fff",
            borderColor: "#eee",
            borderRadius: "4",
            color:"#000"
          },
          defaultProps: {},
          variants: {},
          sizes: {},
      }
  } 
};

export const screenOptions = () => ({
  headerShown: false,
  // cardStyle: {
  //   backgroundColor: "transparent",
  // },
  gestureEnabled: true,
  backgroundColor: "#fff",
  paddingBottom:"0"
});

export default StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  activeTab: {
    backgroundColor : WHITE,
    color : BLUE,
    borderRadius : 0
  },
  stickyNavShadow:{
    shadowColor: BLACK,
    shadowOffset: {
      width: 0,
      height: -5,
    },
    shadowOpacity: 0.18,
    shadowRadius: 5.0,
    elevation: 2
  },
  list: {
    marginTop: 20,
    justifyContent: "center",
    // alignItems: "stretch",
    flexWrap: "wrap",
    padding: "1em",
    // flexFlow: 'row'
  },
  item: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: "#cccccc",
    textAlign: "center",
    width: "100%",
    //margin:'1em 0',
    justifyContent: "center",
    backgroundColor: "#fff",
    shadowColor: "#555",
  },
  header: {
    fontWeight: "bold",
  },
  subheader: {
    paddingTop: 10,
  },

  price: { fontSize: 18, color: BLUE },
  banner: {
    position: "relative",
  },
  bannerImage: {
    height: 400,
  },
  bannerCaption: {
    position: "absolute",
    height: "100%",
    width: "100%",
    fontSize: 25,
  },
  imgBoxContainer: {
    borderColor: "#eee",
    borderWidth:1,
    width: "100%",
    height: 122,
    marginBottom: 10,
    position:"relative"
  },
  imgBoxMobileContainer: {
    borderColor: "#eee",
    borderWidth:1,
    width: "100%",
    height: 90,
    marginBottom: 0,
    backgroundColor: WHITE,
    position:"relative"
  },
  imgBox: {
    width: "100%",
    height: 120,
    backgroundColor: WHITE,
    position:"relative"
  },
  imgBoxPlacholder: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height:"100%",
    width:"100%",
    left:0,
    top:0
  },
  titleRow: {
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 15
   },
   gridContainer : {
    maxWidth:1170,
    marginLeft:"auto",
    marginRight:"auto",
    width:"98%",
    paddingLeft:15,
    paddingRight:15
   },
   gridRow : {
    marginLeft: -10, 
    marginRight: -10
   },
   column: {
     padding:10
   },
   loaderContainer :{ 
    position: "absolute",
    width: "100%",
    marginTop: "20%",
    flex: 1
   },
   filterModalUI:{
    maxHeight:"100%",
    maxWidth: 1170,
    marginBottom:"auto",
    justifyContent:"space-between",
    backgroundColor: WHITE,
   },
   filterModalPopUI :{
    maxHeight:"100%",
    maxWidth: 1170,
    marginBottom:"auto",
    justifyContent:"space-between",
    backgroundColor: WHITE,
    position:"absolute",
    zIndex: 10000
   },
   filterUi : {
     position: "absolute",
     zIndex:10,
     top:47,
     right:15,
     left:15,
     backgroundColor: WHITE,
     borderColor: LIGHT,
     borderWidth: 1,
  },
  displayFlex : {
    display: "flex"
  },
  displayHidden : {
    display: "none"
  },
  filterModal: {
    maxHeight: "100%",
    marginBottom: "auto",
    justifyContent:"space-between",
    backgroundColor: WHITE,
    maxWidth: 1170,
    borderRadius:0
  },
  activeFiltertab:{
    backgroundColor: LIGHT,
  },
  filterUiTabs : {
    borderRightWidth:1,
    marginRight:0,
    borderRightColor:LIGHT,
    minWidth:170,
    width:"20%"
  },
  filterUiContainer : {
    flex:1,
    width:"80%"
  },
  filterUiActiveTab : {
    backgroundColor: LIGHT,
    borderRadius: 8,
    color: WHITE,
  },
  filterUiFooter : {
    backgroundColor: LIGHT,
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  filterUiBody : {
    padding:0,
    maxHeight: "100%",
    backgroundColor: WHITE,
    height:"100%",
    justifyContent:"space-between",
    flexDirection:"row"
  },
  customButtonUi: {
    justifyContent: 'space-between',
    width: "100%",
    flexDirection: "row",
    borderWidth:1,
    borderColor: "#eee",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 0,
  },
  customCheckbox:{
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    marginTop:7,
    marginLeft:3,
    marginRight:3,
    width: 195
  },
  modalCloseBtn : {
    position: 'absolute',
    right:0,
    top:0,
    zIndex: 100,
    backgroundColor: WHITE,
  },
  disabled:{
    opacity:0.5
  },
  scrollContainerStyle: {
    padding: 0,
    backgroundColor: WHITE
  },
  responseTimeBox: {
    position: 'absolute',
    zIndex: 100
  }
});
