import { SET_ALERT, REMOVE_ALERT } from '../actions/type';

const initialState = {
  alertData:null,
  //isAlertShown:false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return {
        ...state, 
        alertData: payload.alertData,
        //isAlertShown: payload.isAlertShown
      };
    case REMOVE_ALERT:
      return {
        ...state, 
        alertData: null,
        //isAlertShown: false
      };
    default:
      return state;
  }
}