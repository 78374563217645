import { viewConfig } from "../../../config";

import {
  ADD_TO_CART,
  GET_CART,
  SELECT_LANGUAGE,
  ADD_PRODUCT_TYPES,
  UPDATE_CONFIG,
  UPDATE_SORTBY,
  UPDATE_SORTBY_IPC,
} from "../actions/type";

const initialState = {
  cartItems: [],
  countryLanguage: "",
  productTypes: [],
  viewConfig: {
    showFilterCount: viewConfig?.showFilterCount || false,
    showSidebarFilterUI: viewConfig?.showSidebarFilterUI || false,
    enableIPCSearch: viewConfig?.enableIPCSearch || false,
    showResponseTime: viewConfig?.showResponseTime || false,
  },
  sortBy: "relevance",
  sortByIpc: { touched: false, option: null },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CART:
      return {
        ...state,
        cartItems: payload,
      };
    case SELECT_LANGUAGE:
      return {
        ...state,
        countryLanguage: payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, payload],
      };
    case ADD_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: [...state.productTypes, ...payload],
      };
    case UPDATE_CONFIG:
      const existingConfig = state.viewConfig;
      existingConfig[Object.keys(payload)[0]] = Object.values(payload)[0];
      return {
        ...state,
        viewConfig: existingConfig,
      };
    case UPDATE_SORTBY:
      return {
        ...state,
        sortBy: payload,
      };
    case UPDATE_SORTBY_IPC:
      return {
        ...state,
        sortByIpc: payload,
      };
    default:
      return state;
  }
}
