import { SET_ALERT, REMOVE_ALERT } from "./type";

export const setAlert = (msg, alertType, timeout) => dispatch => {
  dispatch({
    type: SET_ALERT,
    payload: { 
        alertData: {alertType: alertType, msg: msg, timeout:timeout},
        //isAlertShown: true
         }
  });

  // setTimeout(() => {
  //   dispatch({ type: REMOVE_ALERT});
  // }, timeout);
};

export const removeAlert = (msg, alertType, timeout) => dispatch => {
  dispatch({ type: REMOVE_ALERT});
};