import React, { memo, useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Menu,
  Button,
  Divider,
  ScrollView,
  Container,
  Icon,
  Modal,
  FlatList,
  Skeleton,
  useMediaQuery,
  Center,
  Spinner,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableHighlight, Dimensions, LogBox } from "react-native";

/* Store */
import { useSelector } from "react-redux";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../../../utils/styles";
import { camelToCapitalize, percentage } from "../../../utils/commonFunctions";

const FiltersSQL = ({ filters, appliedFilters, updateFilterAndSort, ipcSortOptions }) => {
  const [isActiveTab, setActiveTab] = useState(null);
  const [sortedArray, setSortedArray] = useState([]);
  const [filterSelectionValue, setFilterSelectionValue] = useState([]);

  const [windowHeight, setWindowHeight] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [isMediumScreen] = useMediaQuery({ maxWidth: 767 });

  const showFilterCount = useSelector((state) => state.appData.viewConfig.showFilterCount);
  const selectedSort = useSelector((state) => state.appData.sortByIpc);

  const [localSelectedSort, setLocalSelectedSort] = useState({ touched: false, option: null });

  const onHandleChangeFilters = (valueId) => {
    setFilterSelectionValue((p) => {
      return p.includes(valueId) ? p.filter((x) => x !== valueId) : [...p, valueId];
    });
  };
  const applyFilters = () => {
    if (localSelectedSort.touched) updateFilterAndSort(filterSelectionValue, localSelectedSort.option);
    else updateFilterAndSort(filterSelectionValue);
  };
  const clearFilters = () => {
    if (localSelectedSort.touched) updateFilterAndSort([], localSelectedSort.option);
    else updateFilterAndSort(filterSelectionValue);
  };

  useEffect(() => {
    setLocalSelectedSort(selectedSort);
  }, [selectedSort]);

  useEffect(() => {
    LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);
    let filterWindowHeight =
      Dimensions.get("window").width > 768
        ? percentage(Dimensions.get("window").height, 80)
        : Dimensions.get("window").height;
    setWindowHeight(filterWindowHeight - 65);

    return () => {
      setShowSpinner(true);
      setWindowHeight(null);
    };
  }, []);

  const getCheckedStatus = (filterName) => {
    return filterSelectionValue.includes(filterName);
  };

  const updateSortValue = (value) => {
    setLocalSelectedSort({ touched: true, option: value });
  };

  useEffect(() => {
    if (isActiveTab === null && sortedArray.length > 0) setActiveTab(sortedArray[0].attributeId);
  }, [sortedArray]);

  useEffect(() => {
    setFilterSelectionValue(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    setSortedArray(filters);
    setShowSpinner(false);
  }, [filters]);

  const renderItem = ({ item }) => (
    <>
      {item.attributeId === isActiveTab && (
        <Box
          key={item.attributeId}
          p={2}
          style={item.attributeId !== isActiveTab ? styles.displayHidden : styles.displayFlex}
        >
          {item.attributeId === isActiveTab && (
            <Box
              mt="2"
              alignItems="flex-start"
              flexWrap={"wrap"}
              flexDirection={{ base: "column", sm: "row" }}
              minWidth="100%"
            >
              {item.options?.map((subitem) => {
                return (
                  <TouchableHighlight
                    underlayColor="none"
                    key={`sub-${subitem.valueId}`}
                    onPress={() => {
                      onHandleChangeFilters(subitem.valueId);
                    }}
                  >
                    <Box style={styles.customCheckbox}>
                      {getCheckedStatus(subitem.valueId) ? (
                        <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box" />} />
                      ) : (
                        <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box-outline-blank" />} />
                      )}
                      <Text isTruncated maxWidth={showFilterCount ? "75%" : "100%"} pl={2}>
                        {subitem.label}
                      </Text>
                      {showFilterCount && (
                        <Text flex={1} pl={2}>
                          ({subitem?.productCount})
                        </Text>
                      )}
                    </Box>
                  </TouchableHighlight>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </>
  );

  return (
    <>
      <Modal.Body flex={1} height="100%" p={0}>
        <Box style={styles.filterUiBody}>
          {showSpinner && (
            <Center flex={1} height={windowHeight}>
              Please wait...
              <Spinner size="sm" accessibilityLabel="Loading..." />
            </Center>
          )}

          {!showSpinner && (
            <>
              <Container height={windowHeight} style={styles.filterUiTabs}>
                <ScrollView w="100%" p={3}>
                  {isMediumScreen && (
                    <Box flex={1} mt={5} width="100%">
                      <Heading size="md" mb={2}>
                        Sort By
                      </Heading>
                      <Menu
                        trigger={(triggerProps) => {
                          return (
                            <Button
                              variant="outine"
                              w="100%"
                              _hover={brandTheme.defaultHover}
                              _pressed={brandTheme.defaultPressed}
                              borderWidth="1px"
                              borderColor="gray.100"
                              {...triggerProps}
                              size="sm"
                              pl={3}
                              {...triggerProps}
                              rightIcon={<Icon as={<MaterialIcons name="arrow-downward" />} size="5" />}
                            >
                              {localSelectedSort.touched ? localSelectedSort.option?.label : "SORT BY"}
                            </Button>
                          );
                        }}
                      >
                        <Menu.OptionGroup
                          title="Sort By"
                          type="radio"
                          value={localSelectedSort.option?.value}
                          onChange={(val) => {
                            updateSortValue(val);
                          }}
                        >
                          {ipcSortOptions.map((item) => (
                            <Menu.ItemOption key={item.value} onPress={() => updateSortValue(item)} value={item.value}>
                              {item.label}
                            </Menu.ItemOption>
                          ))}
                        </Menu.OptionGroup>
                      </Menu>

                      <Divider mt={5} mb={5} />
                    </Box>
                  )}

                  <Heading size="md" mb={2}>
                    Filter By
                  </Heading>

                  {!sortedArray &&
                    Array(5)
                      .fill(0)
                      .map((_, i) => <Skeleton key={i} variant="text" mb={2} height={35} />)}

                  {sortedArray && sortedArray.length === 0 && <Text>Filters not available</Text>}

                  {sortedArray &&
                    sortedArray?.map((item, key) => {
                      return (
                        <Box mb={1} key={`${key}-${item.attributeId}`}>
                          <TouchableHighlight
                            underlayColor={brandTheme.light}
                            onPress={() => {
                              setActiveTab(item.attributeId);
                            }}
                            style={item.attributeId === isActiveTab ? styles.activeFiltertab : ""}
                          >
                            <Box style={styles.customButtonUi}>
                              {camelToCapitalize(item.label)}{" "}
                              <Icon as={<MaterialIcons name="arrow-right" />} size="sm" />
                            </Box>
                          </TouchableHighlight>
                        </Box>
                      );
                    })}
                </ScrollView>
              </Container>
              <Container style={styles.filterUiContainer} height={windowHeight}>
                {!sortedArray && (
                  <Box mt={2} ml={2}>
                    {Array(15)
                      .fill(0)
                      .map((_, i) => (
                        <Skeleton key={i} variant="text" style={styles.customCheckbox} width="100%" height={25} />
                      ))}
                  </Box>
                )}

                {(!sortedArray || sortedArray?.length <= 0) && <Text>Filters not available</Text>}

                {sortedArray && sortedArray.length > 0 && (
                  <FlatList
                    height={windowHeight}
                    w="100%"
                    data={sortedArray}
                    scrollEnabled={false}
                    removeClippedSubviews
                    renderItem={renderItem}
                    keyExtractor={(item) => String(`${item.attributeId}`)}
                  />
                )}
              </Container>
            </>
          )}
        </Box>
      </Modal.Body>

      <Modal.Footer p={0} m={0}>
        <Button.Group space={2} style={styles.filterUiFooter}>
          <Button colorScheme="coolGray" size="lg" variant="ghost" onPress={clearFilters}>
            CLEAR ALL
          </Button>
          <Button colorScheme="danger" size="lg" onPress={applyFilters}>
            APPLY
          </Button>
        </Button.Group>
      </Modal.Footer>
    </>
  );
};

export default memo(FiltersSQL);
