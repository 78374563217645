import { gql } from "@apollo/client";

export { defaultVariables } from "../../config";

export const QUERY_GET_PRODUCTS = gql`
  query getProducts($locale: Locale, $currency: Currency!, $country: Country, $channelId: String!) {
    productProjectionSearch {
      results {
        published
        name(locale: $locale)
        id
        masterVariant {
          images {
            url
            label
          }
          price(currency: $currency, country: $country, channelId: $channelId) {
            value {
              currencyCode
              centAmount
              fractionDigits
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_CATEGORIES = gql`
  query getCategories($locale: Locale) {
    categories(limit: 10, where: "parent is not defined", sort: "orderHint asc") {
      results {
        stagedProductCount
        name(locale: $locale)
        id
        slug(locale: $locale)
      }
    }
  }
`;

export const QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES = gql`
  query rootCategoriesAndProductTypes($locale: Locale, $limit: Int) {
    categories(limit: $limit, where: "parent is not defined", sort: "orderHint asc") {
      total
      count
      results {
        stagedProductCount
        name(locale: $locale)
        id
        slug(locale: $locale)
      }
    }
    productTypes(limit: $limit) {
      total
      count
      results {
        id
        attributeDefinitions(limit: $limit) {
          limit
          total
          results {
            name
            label(locale: $locale)
          }
        }
      }
    }
  }
`;

export const QUERY_GET_CATEGORY_MAPPING_WITH_PRODUCT_TYPES = gql`
  query ($categoryId: String!) {
    productProjectionSearch(
      staged: true
      facetFilters: [
        { model: { tree: { path: "categories.id", rootValues: [$categoryId], subTreeValues: [$categoryId] } } }
      ]
      facets: [{ model: { terms: { path: "productType.id", countProducts: true } } }]
    ) {
      facets {
        facet
        value {
          ... on TermsFacetResult {
            terms {
              term
              productCount
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_CATEGORY_FILTER_FACETS = gql`
  query ($categoryId: String!,$facetQueries: [SearchFacetInput!]) {
    productProjectionSearch(
      staged: true
      facetFilters: [
        {model: {tree: {path: "categories.id", rootValues: [$categoryId], subTreeValues: [$categoryId]}}}
      ]
      facets: $facetQueries
    ){
      facets {
        facet
        value {
          ... on TermsFacetResult {
            terms {
              term
              productCount
            }
          }
        }
      }
    }
  }
`;
export const QUERY_PRODUCTS_BY_CATEGORY = gql`
  query productsByCategory(
    $categoryId: String!
    $locale: Locale
    $currency: Currency!
    $country: Country
    $channelId: String
  ) {
    productProjectionSearch(
      filters: [{ model: { tree: { path: "categories.id", rootValues: [$categoryId], subTreeValues: [$categoryId] } } }]
    ) {
      results {
        categories {
          id
          name(locale: $locale)
        }
        id
        published
        name(locale: $locale)
        masterVariant {
          images {
            url
            label
          }
          price(currency: $currency, country: $country, channelId: $channelId) {
            value {
              currencyCode
              centAmount
              fractionDigits
            }
          }
          attributesRaw {
            name
            value
          }
        }
        variants {
          id
          price(currency: $currency, country: $country, channelId: $channelId) {
            value {
              currencyCode
              centAmount
              fractionDigits
            }
          }
          attributesRaw {
            name
            value
          }
        }
      }
    }
  }
`;

export const QUERY_GET_PRODUCT = gql`
  query Sphere($productId: String!, $locale: Locale, $currency: Currency!, $country: Country, $channelId: String!) {
    product(id: $productId) {
      id
      masterData {
        staged {
          name(locale: $locale)
          description(locale: $locale)
          allVariants {
            id
            images {
              url
              label
            }
            price(currency: $currency, country: $country, channelId: $channelId) {
              value {
                currencyCode
                centAmount
                fractionDigits
              }
            }
            availability {
              channels(includeChannelIds: [$channelId]) {
                results {
                  availability {
                    isOnStock
                  }
                }
              }
            }
            attributesRaw {
              name
              value
              attributeDefinition {
                type {
                  name
                }
                name
                label(locale: $locale)
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_PRODUCTS_BY_CATEGORY_FILTER = gql`
  query productsByCategory(
    $locale: Locale
    $currency: Currency!
    $country: Country
    $channelId: String
    $filterQueries: [SearchFilterInput!]
    $sorts: [String!]
    $offset: Int!
    $categoryId: String!
    $limit: Int!
  ) {
    productProjectionSearch(filters: $filterQueries, staged: true, offset: $offset, limit: $limit,
      facetFilters: [{  model: { tree: { path: "categories.id", rootValues: [$categoryId], subTreeValues: [$categoryId] } } }]
      facets: [{ model: { terms: { path: "productType.id", countProducts: true } } }]
      sorts: $sorts
    ) {
      facets {
        facet
        value {
          ... on TermsFacetResult {
            terms {
              term
              productCount
            }
          }
        }
      }
      total
      results {
        categories {
          id
          name(locale: $locale)
        }
        id
        published
        name(locale: $locale)
        masterVariant {
          images {
            url
            label
          }
          price(currency: $currency, country: $country, channelId: $channelId) {
            value {
              currencyCode
              centAmount
              fractionDigits
            }
          }
          attributesRaw {
            name
            value
          }
        }
        variants {
          id
          price(currency: $currency, country: $country, channelId: $channelId) {
            value {
              currencyCode
              centAmount
              fractionDigits
            }
          }
          attributesRaw {
            name
            value
          }
        }
      }
    }
  }
`;

//#region customer
export const MUTATION_LOGIN = gql`
  mutation Login($email: String!, $password: String!, $anonymousCartId: String) {
    customerSignIn(draft: { email: $email, password: $password, anonymousCartId: $anonymousCartId }) {
      customer {
        email
        id
        firstName
        lastName
      }
      cart {
        customerId
        id
        lineItems {
          # to calculate number of unique items in cart
          productId
        }
      }
    }
  }
`;

export const MUTATION_RESET_PASSWORD = gql`
  mutation resetPassword($tokenValue: String!, $newPassword: String!) {
    customerResetPassword(tokenValue: $tokenValue, newPassword: $newPassword) {
      firstName
    }
  }
`;
//#endregion

//#region carts
export const MUTATION_CREATE_ANONYMOUS_CART = gql`
  mutation createAnonymousCart($currency: String!) {
    createCart(draft: { currency: $currency }) {
      id
    }
  }
`;
//#endregion

//#get ProductTypes
export const PRODUCT_TYPES = gql`
  query Sphere {
    productTypes {
      total
      results {
        key
        attributeDefinitions {
          limit
          offset
          total
          results {
            attributeConstraint
            name
            type {
              name
            }
          }
        }
      }
    }
  }
`;

//#get TypeAheadSearch
export const TYPEAHEAD_SEARCH = gql`
  query Sphere($locale: Locale!, $text: String!) {
    categorySearch(
      fulltext : {
        text: $text
        locale: $locale
      }
    ) {
      offset
      count
      total
      results{
        id
        name(locale: $locale)
      }
    }
  
    productProjectionSearch( 
      text: $text
      locale: $locale
    ) {
      total
      results {
        id
        name(locale: $locale)
      }
    }
  }
`;