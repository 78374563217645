import React, {memo, useEffect, useState} from 'react';
import { HStack, Text, Modal, IconButton, Icon, Switch, Heading} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

/* Store */
import { useSelector, useDispatch } from 'react-redux'
import { updateConfig } from '../../../store/actions/appState'

/*Common Functions & Styles*/
import {brandTheme} from "../../../utils/styles";

const AppConfigModal = () => {
    const [open, setOpen] = useState(false)
    const [showFilterCount, setShowFilterCount] = useState(false)
    const [showFilterSidebar, setShowFilterSidebar] = useState(false)
    const [enableIPCSearch, setEnableIPCSearch] = useState(false)
    const [showResponseTime, setShowResponseTime] = useState(false)

    const viewConfig = useSelector(state => state.appData.viewConfig)

    useEffect(() =>{
        setShowFilterCount(viewConfig.showFilterCount)
        setShowFilterSidebar(viewConfig.showSidebarFilterUI)
        setEnableIPCSearch(viewConfig.enableIPCSearch)
        setShowResponseTime(viewConfig.showResponseTime)
    }, [viewConfig])

    const dispatch = useDispatch()
    const updateConfigSetting = (item) => {
        dispatch(updateConfig(item))
    }

    useEffect(() =>{
        updateConfigSetting({showFilterCount:showFilterCount});
    }, [showFilterCount])

    useEffect(() =>{
        updateConfigSetting({showSidebarFilterUI:showFilterSidebar});
    }, [showFilterSidebar])

    useEffect(() =>{
        updateConfigSetting({enableIPCSearch:enableIPCSearch});
    }, [enableIPCSearch])

    useEffect(() =>{
        updateConfigSetting({showResponseTime:showResponseTime});
    }, [showResponseTime])

    return (
        <>
        <IconButton colorScheme="gray" rounded={50} variant="solid" zIndex={100} position="absolute" bottom={120} right={2} size="lg" shadow={5} icon={<Icon color="white" as={<MaterialIcons name="settings" />} size="sm" />} onPress={()=> setOpen(!open)}
        />

        <Modal safeAreaTop isOpen={open} onClose={() => setOpen(!open)} mt={0}>
            <Modal.Content width={['300']} rounded="0" maxHeight="100%" maxWidth="100%">
                <Modal.Header>
                    <HStack justifyContent="space-between" width="100%">
                        <Heading size={"md"}>Configurations</Heading>
                        <IconButton size="md" p={0} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
                            onPress={() => {setOpen(!open)}}
                            icon={<>{<Icon
                                size="8"
                                color={ brandTheme.inputIcon }
                                as={<MaterialIcons name="close" />}
                            />}</>}
                            />
                    </HStack>
                </Modal.Header>
                <Modal.Body>
                    <HStack alignItems="center" justifyContent="space-between" width="100%" mt={3} mb={3}> 
                        <Text fontSize="sm">Filters in Sidebar (Desktop Mode)</Text>
                        <Switch size="md" onToggle={(value)=>setShowFilterSidebar(value)} isChecked={showFilterSidebar}/>
                    </HStack>
                    <HStack alignItems="center" justifyContent="space-between" width="100%" mt={3} mb={3}> 
                        <Text fontSize="sm">Show product count with Filters</Text>
                        <Switch size="md" onToggle={(value)=>setShowFilterCount(value)} isChecked={showFilterCount}/>
                    </HStack>
                    <HStack alignItems="center" justifyContent="space-between" width="100%" mt={3} mb={3}> 
                        <Text fontSize="sm">Enable IPC Typeahead Search</Text>
                        <Switch size="md" onToggle={(value)=>setEnableIPCSearch(value)} isChecked={enableIPCSearch}/>
                    </HStack>
                    <HStack alignItems="center" justifyContent="space-between" width="100%" mt={3} mb={3}> 
                        <Text fontSize="sm">Show response time on PLP pages &amp; Typeahead search</Text>
                        <Switch size="md" onToggle={(value)=>setShowResponseTime(value)} isChecked={showResponseTime}/>
                    </HStack>
                </Modal.Body>
            </Modal.Content>
          </Modal>
        </>
        )
}


export default memo(AppConfigModal);