import React, { useEffect, useState } from "react";
import { Text, Image, Box, HStack, VStack } from "native-base";

/* config Import */
import { defaultVariables } from "../../../../config";

/*Common Functions & Styles*/
import styles from "../../../utils/styles";

/* Import Images */
import PlaceholderImage from "../../../../assets/img-placeholder.png";

const ProductItemMobile = ({ product }) => {
  const getMappedPrice = (price, currency) => {
    if (price) {
      let priceCurrency = currency || defaultVariables.currency;
      return `${priceCurrency == "INR" ? "₹" : priceCurrency} ${parseFloat(price).toFixed(2)}`;
    }
    return false;
  };

  return (
    <HStack space={3}>
      <Box w="40%">
        {!product.attributes.images ||
          (product.attributes.images.length <= 0 && (
            <Image
              style={styles.imgBoxMobileContainer}
              source={PlaceholderImage}
              alt={product.name}
              resizeMode="contain"
            />
          ))}
        {product.attributes.images && product.attributes.images.length > 0 && (
          <Image
            style={styles.imgBoxMobileContainer}
            source={{ uri: product.attributes.images[0] }}
            alt={product.name}
            resizeMode="contain"
          />
        )}
      </Box>
      <VStack flex={1}>
        <Text fontSize={17} noOfLines={2} lineHeight={22}>
          {product.name}
        </Text>
        <Text fontSize={27}>
          {(product.attributes.price &&
            product.attributes.price.length > 0 &&
            getMappedPrice(product.attributes.price[0])) ||
            ""}
        </Text>
      </VStack>
    </HStack>
  );
};

export default ProductItemMobile;
