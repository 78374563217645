import React, { memo } from 'react';
import { Skeleton, Box, HStack, VStack } from 'native-base';
import styles from '../../../utils/styles';

const ProductItemMobileSkeleton = () => {
    return (
        <>
            <Box mb={2}>
                <HStack space={3}>
                    <Box w="40%">
                        <Skeleton variant="react" style={styles.imgBoxMobileContainer} />
                    </Box>
                    <VStack flex={1}>
                        <Skeleton variant="text" height={22} mb={2} />
                        <Skeleton variant="text" height={35} />
                    </VStack>
                </HStack>
            </Box>
        </>
    );
}

export default memo(ProductItemMobileSkeleton);