import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistCache } from "apollo3-cache-persist";
import { HttpLink } from 'apollo-link-http';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink  } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import SdkAuth from "@commercetools/sdk-auth";

import { config } from "../../config";
import fetch from "node-fetch";

export default async function createApolloClient() {
  const ctAuthClient = new SdkAuth({
    ...config.ct.auth,
    fetch,
  });

  const httpLinkURL = new HttpLink({ uri: `${config.ct.api}/${config.ct.auth.projectKey}/graphql` });

  // const ctHttpLink = createHttpLink({
  //   uri: `${config.ct.api}/${config.ct.auth.projectKey}/graphql`,
  // });

  const ctTokenCode = await ctAuthClient.anonymousFlow();
  AsyncStorage.setItem('token',ctTokenCode.access_token);

  // const ctAuthLink = setContext((_, { headers },) => {
  //   const token = ctTokenCode.access_token;
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `Bearer ${token}` : "",
  //     },
  //   };
  // });

  
  //getting response time
  const roundTripLink = new ApolloLink((operation, forward) => {
    // Called before operation is sent to server
    operation.setContext({ start: new Date() });
    operation.setContext(({ headers }) => ({ headers: {
      ...headers,
      authorization: ctTokenCode.access_token ? `Bearer ${ctTokenCode.access_token}` : "",
    }}));
  
    return forward(operation).map((data) => {
      // Called after server responds
      if(operation.operationName){
        const time = new Date() - operation.getContext().start;
        console.log(`GraphQL Operation ${operation.operationName} took ${time} to complete`);
        Object.assign(data.data, { responsetime:time})
      }
      return data;
    });
  });

  const cache = new InMemoryCache();
  await persistCache({
    cache,
    storage: AsyncStorage,
  });

  const ctApolloClient = new ApolloClient({
    //link: ctAuthLink.concat(ctHttpLink),
    link: roundTripLink.concat(httpLinkURL),
    cache,
  });

  return ctApolloClient;
}
