import React, {memo} from 'react';
import { Image, Text, Flex } from "native-base";

const FlagOption = ({language,flagUpdate,fontSize,isSmallScreen}) => {
    return (
            <Flex align="center" justify="center" direction="row" minWidth={isSmallScreen?"40px":"95px"}>
                { flagUpdate && language.img && <Image
                style={{ width: 25, height: 25 }}
                source={language.img}
                alt={ language.label } mr="2"/>}
                {!isSmallScreen && <Text fontSize={fontSize} >{ language.label }</Text>}
            </Flex>
    );
}

export default memo(FlagOption);