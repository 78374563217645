// import config from "../config";

import {  ADD_TO_CART, GET_CART, SELECT_LANGUAGE, ADD_PRODUCT_TYPES, UPDATE_CONFIG, UPDATE_SORTBY, UPDATE_SORTBY_IPC } from "./type";
import { setAlert } from "./alert";

import axios from "axios";

var API_URL = '' //config.BASE_HREF;

export const getCart = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
  };
  try {
    // const res = await axios.get(
    //   API_URL+"cart",
    //   config
    // );
    dispatch({
      type: GET_CART,
      payload: ""//res.data,
    });
  } catch (error) {
    console.log("err", error);
  }
};


export const selectLanguage = (data) => async (dispatch) => {
  dispatch({
    type: SELECT_LANGUAGE,
    payload: data,
  });
};

export const addToCart = (data) => async (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: data,
  });
  dispatch(setAlert("Added product to the cart", "success", 5000));
};

export const addProductTypes = (data) => async (dispatch) => {
  dispatch({
    type: ADD_PRODUCT_TYPES,
    payload: data,
  });
};

export const updateConfig = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_CONFIG,
    payload: data,
  });
};

export const updateSortBy = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_SORTBY,
    payload: data,
  });
};

export const updateSortByIpc = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_SORTBY_IPC,
    payload: data,
  });
};

// export const getProductdetailsMappings = (plpData, onSucesscallback) => async dispatch => {
//   const config = {
//     headers: { 'Content-Type': 'application/json' },
//   }
//   try {
//     const res = await axios.post(
//       'http://api.ncc.n7.io/v1/nitro/ncc/productdetails/plp',
//       plpData,
//       config,
//     )
//     onSucesscallback(res)
//     // return res
//   } catch (error) {
//     console.log('err', error)
//   }
// }


