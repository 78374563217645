/* Default Imports */
import * as React from "react";
import { Button, Text, View, Center, Image, ScrollView, Box, Heading, SimpleGrid } from "native-base";

/*GrapghQL*/
import { useQuery } from "@apollo/client";
import { defaultVariables, QUERY_GET_PRODUCTS } from "../apis/graphql";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../utils/styles";
import { formatNametoUrl, getPrice} from "../utils/commonFunctions"

/* Components */
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobilieStickyNav from "../components/MobilieStickyNav";

import { IPC_CONFIG } from "../../config";

const Home = ({ navigation, route }) =>{
  const { data } = useQuery(QUERY_GET_PRODUCTS, { variables: defaultVariables, fetchPolicy: "cache-and-network" });

  return (
    <>
      <Header navigation={navigation} route={route}/>
      <ScrollView pb="0" bg={ brandTheme.white }>
        <Box shadow={2} style={styles.banner}>
          <Center>
            <View width="100%">
              <Image
                style={styles.bannerImage}
                source={require("../../assets/banner1.jpg")}
                alt="Offer"
                resizeMode="cover"
              />
              <View style={styles.bannerCaption}>
                <Center flex={1}>
                  <Text fontSize="4xl" fontWeight="bold" color={brandTheme.white}>
                    Checkout
                  </Text>
                  <Text fontWeight="bold" fontSize="5xl" mb={2} color={brandTheme.brandColor}>
                    Our Collection
                  </Text>
                  <Button height="50px" onPress={() => navigation.push("product-category-ipc", { category: 'leather-goods', id: IPC_CONFIG.ctCatsToPreload[0] })}
                  bg={ brandTheme.bannerButtonBg } _hover={{ bg: brandTheme.brandColor }} _pressed={{ bg: brandTheme.brandColor }}>SHOP NOW</Button>
                </Center>
              </View>
            </View>
          </Center>
        </Box>

        <Box style={styles.gridContainer}>
          <Box my={8} w="100%">
            <Heading size="lg" textAlign={{base:"center", lg:"left"}} mb={2}>{('Recommendations').toUpperCase()}</Heading>
            <SimpleGrid minChildWidth={{base:"50%", sm:200, lg:"25%"}} justifyContent={{base:"center", lg:"flex-start"}} spacing={0} style={styles.gridRow}>
                {data?.productProjectionSearch?.results?.map((product, key) => {
                  if(key<4){
                  return (
                    <Box key={key} rounded="lg" style={styles.column}>
                      <Image
                          style={styles.imgBox}
                          source={{ uri: product?.masterVariant?.images[0]?.url }}
                          alt={product?.name}
                          resizeMode="contain"
                        />
                        <Text textAlign="center" width={[155, 200]} mx="auto" noOfLines={2} height={45} px={2}>{product?.name}</Text>
                        <Text textAlign="center" fontSize={30}>
                          {getPrice(product?.masterVariant.price)}
                        </Text>
                        <Button onPress={() => navigation.push("product-detail", { name: formatNametoUrl(product.name), id:product.id })} size="sm" width="100px" mx="auto">SHOP NOW</Button>
                    </Box>
                  )}
                })}
              </SimpleGrid>
          </Box>

          <Box my={8} w="100%">
            <Heading size="lg" textAlign={{base:"center", lg:"left"}} mb={2}>{('Best Sellers').toUpperCase()}</Heading>
            <SimpleGrid minChildWidth={{base:"50%", sm:200, lg:"25%"}} justifyContent={{base:"center", lg:"flex-start"}} spacing={0} style={styles.gridRow}>
              {data?.productProjectionSearch?.results?.map((product, key) => {
                if(key<3){
                  return (
                    <Box key={key} rounded="lg" style={styles.column}>
                      <Image
                          style={styles.imgBox}
                          source={{ uri: product?.masterVariant?.images[0]?.url }}
                          alt={product?.name}
                          resizeMode="contain"
                        />
                        <Text textAlign="center" width={[155, 200]} mx="auto" noOfLines={2} height={45} px={2}>{product?.name}</Text>
                        <Text textAlign="center" fontSize={30}>
                          {getPrice(product?.masterVariant.price)}
                        </Text>
                        <Button onPress={() => navigation.push("product-detail", { name: formatNametoUrl(product.name), id:product.id })} size="sm" width="100px" mx="auto">SHOP NOW</Button>
                    </Box>
                  );
                }
              })}
            </SimpleGrid>
          </Box>
        </Box>

        <Footer navigation={navigation}/>
      </ScrollView>

      {/* Mobile: sticky bottom nav */}
      <MobilieStickyNav navigation={navigation} route={route}/>
    </>
  );
}

export default Home;
