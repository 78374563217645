export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GET_CART = 'GET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const ADD_PRODUCT_TYPES = 'ADD_PRODUCT_TYPES';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_SORTBY = 'UPDATE_SORTBY';
export const UPDATE_SORTBY_IPC = 'UPDATE_SORTBY_IPC';

