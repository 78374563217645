/* Default Imports */
import React, { useState, useEffect, useRef } from "react";
import { Button, Text, Image, ScrollView, Box, Heading, Radio, HStack, Icon, useMediaQuery, Stagger, IconButton, Alert } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { Dimensions } from 'react-native';

/*GrapghQL*/
import { useLazyQuery } from "@apollo/client";
import { defaultVariables, QUERY_GET_PRODUCT } from "../apis/graphql";

/* Store */
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from '../store/actions/appState'

/*Common Functions & Styles*/
import styles, {brandTheme} from "../utils/styles";
import { getPrice, getStatusColor, capitalize } from "../utils/commonFunctions";

/* Components */
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobilieStickyNav from "../components/MobilieStickyNav";
import ProductDetailsSkeleton from "../components/commomAssets/skeletons/ProductDetailsSkeleton";

const ProductDetail = ({ navigation, route }) => {
    const [loadData, { data }] = useLazyQuery(QUERY_GET_PRODUCT, { fetchPolicy: "no-cache",nextFetchPolicy: "no-cache" })

  const [ showLoading, setShowLoading ] = useState(true);
  const [ productsData, setProductsData] = useState(null)
  const [ productsVariants, setProductsVariantsData] = useState(null)
  const [ selectedProductVariant, setSelectedProductVariant] = useState(null)
  const [ productDetails, setProductDetails] = useState(null)
  const [ productSizes, setProductSizes] = useState(null)
  const [ variantSize, setVariantSize] = useState(null)
  const [ productColors, setProductColors] = useState(null)
  const [ isInSale, setIsInSale] = useState(false)
  const [ errorMsg, setError] = useState()
  const [isMediumScreen] = useMediaQuery({ maxWidth:767 });
  const [windowHeight, setWindowHeight] = useState(null)
  const [duration, setDuration] = useState(null)

  /* common functions */
  const showResponseTime = useSelector(state => state.appData.viewConfig.showResponseTime)
  const scrollviewRef = useRef(null);
  const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
  const CONTENT_OFFSET_THRESHOLD = windowHeight/3;

  const scrollToTop = () => {
    scrollviewRef.current.scrollTo({ y: 0, animated: isMediumScreen? true : false })
  }

  const handleBack = () => {
    if(navigation.canGoBack()){
      navigation.pop()
    }else{
      navigation.push("product-category", { category: route?.params?.category, id: route?.params?.categoryId })
    }
  }

  useEffect(() => {
    let windowHeight = Dimensions.get('window').height;
    setWindowHeight((windowHeight-178))
  }, [Dimensions.get('window').width])

  useEffect(() => {
    setShowLoading(true)
    if(data?.product){
      setDuration(`${data.responsetime} ms`)

      let rawData = {allVariants:null, name:null, description:null}
      rawData.name = data?.product?.masterData?.current?.name || data?.product?.masterData?.staged?.name
      rawData.description = data?.product?.masterData?.current?.description || data?.product?.masterData?.staged?.description
      let rawVariantsData = []
      /** get Size & color*/
      rawVariantsData =  (data?.product?.masterData?.current?.allVariants || data?.product?.masterData?.staged?.allVariants)?.map( item=> {
        var filterItem = item.attributesRaw.filter( subitem => {
           return subitem.name === 'size'
        })
  
        var filterItem2 = item.attributesRaw.filter( subitem => {
          return subitem.name === 'color'
        })
        
        if(filterItem.length>0 && filterItem2.length>0){
             return {...item, ...{[filterItem[0].name]: filterItem[0].value}, ...{[filterItem2[0].name]: filterItem2[0].value[0]}}
        }
  
        else if(filterItem.length>0 && filterItem2.length<1){
          return {...item, ...{[filterItem[0].name]: filterItem[0].value}}
        }
  
        else if(filterItem.length<1 && filterItem2.length>0){
            return {...item, ...{[filterItem2[0].name]: filterItem2[0].value[0]}}
        }
  
        else{
           return item
        }
       });
  
      if(rawData){
        rawData.allVariants = rawVariantsData
      }
      if(rawData.allVariants){
        let rawSizes = []
        let rawColors = []
        rawData.allVariants.forEach(item=> {if(item.size){rawSizes.push(item.size)}})
        rawData.allVariants.forEach(item=> {if(item.color){rawColors.push(item.color)}})
        setProductSizes(rawSizes);
        setProductColors(rawColors);
        setProductsData(rawData)
      }
    }
  }, [data])

  useEffect(() => {
    var filteredItem = [];
    productsData?.allVariants?.forEach( item=> {
       var filterItem = item.attributesRaw.filter( subitem => {
          return subitem.name === 'size' && subitem.value.key == "Small"}) 
        if(filterItem.length>0){
            filteredItem.push(item)
        }
      })

      if(filteredItem.length<1){
        filteredItem.push(productsData?.allVariants[0])
      }

    setProductsVariantsData(productsData?.allVariants)
    setSelectedProductVariant(filteredItem[0]);
  }, [productsData])

  useEffect(() => {
    const productDetails = []
    const productVariantData = []
    // console.log('selectedProductVariant size', selectedProductVariant)
    setVariantSize(selectedProductVariant?.size?.key)
    selectedProductVariant?.attributesRaw?.forEach(item => {
      if(item.attributeDefinition.type.name !== 'set' && item.attributeDefinition.type.name !== 'enum' && item.attributeDefinition.type.name !== 'boolean'){
        productDetails.push({"label": ( item.attributeDefinition.label !=="" ? item.attributeDefinition.label : item.attributeDefinition.name), "value": item.value})
      }
      else{
        productVariantData.push({"label": ( item.attributeDefinition.label !=="" ? item.attributeDefinition.label : item.attributeDefinition.name), "value": item.value})
      }
    })
    //console.log('selectedProductVariant', productDetails, selectedProductVariant, productsData, data)
    setProductDetails(productDetails);
    setShowLoading(false)
  }, [selectedProductVariant])

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if(route.params.id){
        loadData({ variables: { ...defaultVariables, productId: route.params.id} })
      }
    }
    return () => {
      mounted = false;
    }
  }, []);

  const dispatch = useDispatch()
  const addItemToCart = (item) => {
      dispatch(addToCart(item))
  }

  return (
    <>
      <Header navigation={navigation} route={route}/>
      <ScrollView pt={1} bg={ brandTheme.white }
        ref={scrollviewRef}
        onScroll={({nativeEvent}) => {
          setContentVerticalOffset(nativeEvent?.contentOffset?.y);
        }}
        scrollEventThrottle={200}>
        <Box minHeight={windowHeight}>
          {/* { !productsData && !selectedProductVariant && 
            <Center flex={1} mt="10" minHeight={200}>
              <Spinner size="lg" accessibilityLabel="Loading..." />
            </Center>
          } */}

          { errorMsg && !showLoading && <p>{ errorMsg }</p>}

          {(showLoading || (!productsData && !selectedProductVariant)) && <ProductDetailsSkeleton/>}

          {!showLoading && 
            <Box style={styles.gridContainer} my={5}>
              {productsData && !isMediumScreen && <HStack><Button onPress={handleBack} variant="ghost" rounded="0" colorScheme="default" mt={-5} leftIcon={<Icon as={<MaterialIcons name="arrow-back" />} size="5" />}  _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>Back</Button></HStack>}
              {productsData && selectedProductVariant && (
                <Box display="flex" flexDirection={{base:"column", md:"row"}} justifyContent={"flex-start"} spacing={3} style={ styles.gridRow }>
                  <Box flex={1} width={["100%", "100%", "40%"]} height={ [ "100%", 400, 500 ]} style={ styles.column }>
                    
                    { selectedProductVariant?.images[0]?.url && <Image
                      source={{ uri: selectedProductVariant?.images[0]?.url }}
                      alt={productsData.name}
                      resizeMode="contain"
                      width={ '100%' }
                      height={ '100%'}
                      minHeight={ 300 }
                      borderWidth={1}
                      borderColor= { brandTheme.light}
                      backgroundColor={ brandTheme.light }
                    />}
                  </Box>
                  <Box width={["100%", "100%", "60%"]} style={ styles.column }>
                    <Heading fontWeight={400}>{productsData.name}</Heading>
                    {/* <Box>
                    <Radio.Group name="myRadioGroup" accessibilityLabel="favorite number" value={value} onChange={(nextValue) => {   setValue(nextValue) }} >
                      <Radio value="one" my={1}> </Radio>
                    </Radio.Group>
                    </Box> */}
                    {selectedProductVariant?.size?.label && <Text fontSize={20} fontWeight={600} mt={5}>Size:
                    {productSizes.length>0 && 
                      productSizes.map( (item,key) => {
                        return(
                          <Radio.Group
                            name="prodSize"
                            value={variantSize}
                            ml={3}
                            onChange={(nextValue) => {
                              setVariantSize(nextValue);
                            }}
                          >
                            <Radio value={item.key} my={1} mr={3}>
                              {item.label}
                            </Radio>
                          </Radio.Group>
                        )
                      })
                    }
                    </Text>}
                    {selectedProductVariant?.color && <Text fontSize={20} mt={5} fontWeight={600}>Color: { selectedProductVariant?.color }</Text>}
                    

                    <Text fontSize={50} fontWeight={600} mt={2}>{ getPrice(selectedProductVariant?.price)}</Text>
                    <HStack space={4} my={3} alignItems="flex-start">
                      <Button onPress={() => alert('Book Now')} size="lg">BOOK NOW</Button>
                      <Button onPress={() => addItemToCart(productsData)} variant="outline" size="lg">Add to Cart</Button>
                    </HStack>

                    {productDetails.length > 1 && 
                      <>
                        <Heading size="md" mt={3} mb={3}>Product Details</Heading>
                        <Box borderWidth={1} px={3} py={1} borderColor= { brandTheme.light}>
                          { productDetails?.map( (item,key) => {
                            return(<Box key={key} display="flex" flexDirection={{base:"row"}} justifyContent={"flex-start"} spacing={3} style={ styles.gridRow }>
                                  <Box flex={1} width={["60%", "40%", "25%"]} style={ styles.column }><Heading size="sm">{ capitalize(item.label)}</Heading></Box>
                                  <Box width={["60%", "60%", "75%"]} style={ styles.column }>
                                    <Text>{ item.value }</Text>
                                  </Box>
                                </Box>
                              )
                            })
                        }
                      </Box>
                    </>
                    }
                  </Box>
                </Box>
              )}
            </Box>
          }
        </Box>
        <Footer navigation={navigation}/>
      </ScrollView>

      {/* Mobile: sticky bottom nav */}
      <MobilieStickyNav navigation={navigation} route={route}/>

      {/* Scroll to Top button starts */}
      <Stagger
          visible={contentVerticalOffset > CONTENT_OFFSET_THRESHOLD}
          initial={{
            opacity: 0,
            bottom:0
          }}
          animate={{
            scale: 1,
            bottom: 30,
            transition: {
              type: "spring",
              mass: 0.8,
              stagger: {
                offset: 30,
                reverse: true,
              },
            },
          }}
          exit={{
            bottom: 0,
            opacity: 0,
            transition: {
              duration: 100,
              stagger: {
                offset: 30,
                reverse: true,
              },
            },
          }}
        ><IconButton
          rounded={50}
          variant="solid"
          position="absolute"
          bottom={27}
          right={ isMediumScreen ? 16 : 2}
          size="lg"
          shadow={5}
          isDisabled={showLoading}
          icon={<Icon color="white" as={<MaterialIcons name="arrow-upward" />} size="sm" />}
          onPress={scrollToTop}
        />
      </Stagger>
      {/* Scroll to Top button ends */}

      {/* show Response Time starts */}
      {duration && showResponseTime && <Alert px={1} py={0} bottom={0} rounded={0} right={0} style={styles.responseTimeBox} status={getStatusColor(duration)}>
            <Text fontSize="sm">{duration}</Text>
      </Alert>}
      {/* show Response Time starts */}
    </>
  );
}

export default ProductDetail;