import React, { memo, useEffect, useState } from 'react';
import { Box, Text, Heading, Menu, Button, Divider, Icon, FlatList } from 'native-base';
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableHighlight } from 'react-native';

/*Lodash */
import { sortBy } from 'lodash';

/* Store */
import { useSelector, useDispatch } from 'react-redux'
import { updateSortBy } from '../../../store/actions/appState';

/*GrapghQL*/
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_CATEGORY_FILTER_FACETS } from "../../../apis/graphql";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../../../utils/styles";
import { camelToCapitalize, ctSortOptions } from '../../../utils/commonFunctions';

const FiltersSidebarCT = ({categoryId, facets, appliedFilters, onApplyFilterClick, filterRefreshed, refreshPagewithSort}) => {
    const [ getFiltersData, { data }] = useLazyQuery(QUERY_GET_CATEGORY_FILTER_FACETS, { fetchPolicy: "cache-and-network",  nextFetchPolicy: "cache-first" });
    const [relevantProductTypes, setRelevantProductTypes] = useState(null)
    const [filters, setFilterFacets] = useState(null)
    const [ isActiveTab, setActiveTab] = useState();
    const [filterSelectionValue, setFilterSelectionValue] = useState([])
    const [selectedSort, setSelectedSort] = useState("")

    const allProductTypes = useSelector(state => state.appData.productTypes)
    const showFilterCount = useSelector(state => state.appData.viewConfig.showFilterCount)
    const sortValue = useSelector(state => state.appData.sortBy)

    const getTagName = (name) =>{
        let rawName= name.split('variants.attributes.')[1]
        return camelToCapitalize(relevantProductTypes.filter(item => item.name === rawName)[0]?.label || rawName)
    }

    const onHandleChangeFilters = (filterName,groupKey) =>{
        let filterNameValue = `${groupKey}:${filterName}`;
        let checkitem = filterSelectionValue.filter(item => item == filterNameValue)
        if(checkitem.length == 0){
            setFilterSelectionValue([ ...filterSelectionValue, filterNameValue])
        }
        else{
            setFilterSelectionValue(filterSelectionValue.filter(item => item !==filterNameValue))
        }
      }

    const getCheckedStatus = (filterName,groupKey) => {
        let filterNameValue = `${groupKey}:${filterName}`;
        let checkitem = filterSelectionValue.filter(item => item == filterNameValue)
        return checkitem.length !== 0;
    }
    
    const dispatch = useDispatch()

    const updateSortValue = (value) => {
        dispatch(updateSortBy(value))
        setSelectedSort(value)
    }

    useEffect(()=>{
        if(selectedSort !== ""){
            refreshPagewithSort(selectedSort)
        }
      },[selectedSort])
    
    useEffect(()=>{
        onApplyFilterClick(filterSelectionValue)
    },[filterSelectionValue])

    useEffect(() => {
        let facetTermsArray = []
        let productTypesArray = []
        facets?.forEach( item => {
            item.value.terms.forEach(subiitem=>{
                facetTermsArray.push(subiitem.term)
            })
        });

        facetTermsArray.forEach(item => {
            let filteredItem = allProductTypes?.filter(subiitem=>subiitem?.id==item);
            if(filteredItem.length>0){
                productTypesArray = [...productTypesArray, ...filteredItem[0].attributeDefinitions.results]
            }
        })

        setRelevantProductTypes(productTypesArray)

        let productFacetsArray = []
        productTypesArray.forEach(item => {
            let facetModel = {model: { terms: { path: `variants.attributes.${item.name}`, countProducts: true} }}
            productFacetsArray.push(facetModel)
        })

        if(productFacetsArray.length>0){
            getFiltersData({ variables: {  categoryId: categoryId, facetQueries: productFacetsArray }})
        }
      }, [allProductTypes])

    useEffect(() => {
        if(data?.productProjectionSearch?.facets){
            let filterFacets = data?.productProjectionSearch?.facets
            var filteredArray = sortBy(filterFacets, [filter => filter.facet.toLowerCase()])
            let sortedFacets = []
            filteredArray.forEach(item => {
                let sortedItem = JSON.parse(JSON.stringify(item));
                sortedItem.value.terms = sortBy(sortedItem.value.terms, [filter => filter.term.toLowerCase()])
                if(sortedItem.value.terms.length>0){
                    sortedFacets.push(sortedItem)
                }
            })   
            setFilterFacets(sortedFacets)
            setActiveTab(sortedFacets[0]?.facet)
        }
      }, [data])

    useEffect(()=>{
        if(filters && filterRefreshed){
            setFilterSelectionValue(appliedFilters)
        }
    },[filters, filterRefreshed])
    
    const renderItem = ({ item }) => (
        <>
            {
               item.facet === isActiveTab && item?.value?.terms?.map( (subitem,key) => {
                    return <TouchableHighlight underlayColor="none" key={`sub-${key+subitem.term}`} onPress={()=>{onHandleChangeFilters(subitem.term,item.facet)}}>
                        <Box style={styles.customCheckbox}>
                            { getCheckedStatus(subitem.term,item.facet)?<Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box" />}/>:
                            <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box-outline-blank" />}/>}
                            <Text isTruncated maxWidth={showFilterCount?"75%":"100%"} pl={2}>{subitem.term}</Text>
                            {showFilterCount && <Text flex={1} pl={2}>({ subitem?.productCount})</Text>}
                        </Box>
                    </TouchableHighlight >
                })
            }
        </>
        )


    return (
        <Box>
            <Box flex={1} width="100%">
                <Heading size="md" mb={2}>Sort By</Heading> 
                <Menu
                trigger={(triggerProps) => {
                    return (
                    <Button variant="outine" w="100%" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} borderWidth="1px" borderColor="gray.100" {...triggerProps} size="sm" pl={3} {...triggerProps} rightIcon={<Icon as={<MaterialIcons name="arrow-downward" />} size="5" />}>{sortValue?ctSortOptions.filter(item=> item.value ===sortValue)[0].label:"SORT BY"}</Button>
                    )
                }}
                >
                <Menu.OptionGroup title="Sort By" type="radio" value={sortValue} onChange={(val) => {updateSortValue(val) }}>
                    { ctSortOptions.map( (item,key) => (<Menu.ItemOption key={key} onPress={()=>updateSortValue(item.value)} value={item.value}>{item.label}</Menu.ItemOption>))}
                </Menu.OptionGroup>
                </Menu>
                
                <Divider mt={5} mb={5}/>
            </Box>

            <Heading size="md" mb={2}>Filter By</Heading>

            {(!filters || filters?.length <= 0) && <Text>Filters not available</Text>}

            { filters && filters?.map( (filterItem,key) => {return(
                <Box mb={1} key={key+filterItem.facet}>
                    <TouchableHighlight underlayColor={brandTheme.light}
                            onPress={()=>{setActiveTab(filterItem.facet);}}
                            style={ filterItem.facet === isActiveTab? styles.activeFiltertab :  ''}
                        >
                        <Box style={ styles.customButtonUi } >{ getTagName(filterItem.facet)} <Icon as={<MaterialIcons name={filterItem.facet === isActiveTab? "keyboard-arrow-down" : "keyboard-arrow-right"} />} size="sm"/></Box>
                    </TouchableHighlight>
                    
                    {filterItem.facet === isActiveTab && 
                        <FlatList w="100%" maxHeight={165}
                            data={filters}
                            mb={2}
                            removeClippedSubviews
                            renderItem={renderItem}
                            keyExtractor={(item) => item.facet}
                        />
                        }
                </Box>)
                })
            }
        </Box>
    );
}

export default memo(FiltersSidebarCT);