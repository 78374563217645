/* Default Imports */
import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  SimpleGrid,
  Text,
  ScrollView,
  Box,
  Heading,
  Button,
  Icon,
  Menu,
  Alert,
  IconButton,
  Tag,
  useMediaQuery,
  FlatList,
  HStack,
  Skeleton,
  Stagger,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { Dimensions, TouchableHighlight, Platform, LogBox } from "react-native";

/* Store */
import { useSelector, useDispatch } from "react-redux";
import { updateSortByIpc } from "../store/actions/appState";

/*Common Functions & Styles*/
import { getName, formatNametoUrl, getStatusColor } from "../utils/commonFunctions";
import styles, { brandTheme } from "../utils/styles";

/* Components */
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobilieStickyNav from "../components/MobilieStickyNav";
import FiltersSQL from "../components/commomAssets/filters/Filters-SQL";
import FiltersSidebarSQL from "../components/commomAssets/filters/FiltersSidebar-SQL";
import ProductItemDesktop from "../components/commomAssets/product/ProductItemDesktop";
import ProductItemMobile from "../components/commomAssets/product/ProductItemMobile";
import ProductItemDesktopSkeleton from "../components/commomAssets/skeletons/ProductItemDesktopSkeleton";
import ProductItemMobileSkeleton from "../components/commomAssets/skeletons/ProductItemMobileSkeleton";

/* IPC */
import { IPC_CONFIG } from "../../config";
import { init } from "../ipc/ipc";
const queryManager = init(IPC_CONFIG);

const defaultLimit = 20;
const defaultProductQueryParams = {
  filters: [],
  sort: { attributeId: null, order: 0 },
  pagination: { offset: 0, limit: defaultLimit },
};
const defaultSortOptions = [{ attributeId: null, label: "Relevance", order: 0, value: "0.null" }];

const ProductCategoryListingPageSQL = ({ navigation, route }) => {
  const [productService, setProductService] = useState(null);
  const [productQueryParams, setProductQueryParams] = useState(defaultProductQueryParams);
  const [showLoading, setShowLoading] = useState(true);
  const [isShowFilter, setShowFilter] = useState(false);
  const [isMediumScreen] = useMediaQuery({ maxWidth: 767 });
  const [productsData, setProductsData] = useState(null);
  const [productsTotal, setProductsTotal] = useState(undefined);
  const [categoryName, setCategoryName] = useState(null);
  const [categorySlug, setCategorySlug] = useState(null);
  const [filters, setFilterFacets] = useState(null);
  const [sortOptions, setSortOptions] = useState(defaultSortOptions);
  const [windowHeight, setWindowHeight] = useState(null);
  const [duration, setDuration] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const showSidebarFilterUI = useSelector((state) => state.appData.viewConfig.showSidebarFilterUI);
  const showResponseTime = useSelector((state) => state.appData.viewConfig.showResponseTime);
  const selectedSort = useSelector((state) => state.appData.sortByIpc);

  const scrollviewRef = useRef(null);
  const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
  const CONTENT_OFFSET_THRESHOLD = windowHeight / 3;

  const dispatch = useDispatch();
  const updateSortValue = (sortOption) => {
    dispatch(updateSortByIpc({ touched: true, option: sortOption }));
  };

  useEffect(() => {
    if (!selectedSort.touched) return;
    if (selectedSort.option.value === productQueryParams.sort.value) return;

    setProductsData([]);
    setShowLoading(true);
    setProductQueryParams((p) => ({
      ...p,
      sort: selectedSort.option,
      pagination: { offset: 0, limit: defaultLimit },
    }));
  }, [selectedSort]);

  // filters and sort changed from modal
  const updateFilterAndSort = (filters, sort) => {
    setProductsData([]);
    setShowLoading(true);

    if (sort !== undefined) {
      setProductQueryParams({
        pagination: { offset: 0, limit: defaultLimit },
        filters,
        sort,
      });
      updateSortValue(sort);
    } else {
      setProductQueryParams((p) => ({
        ...p,
        pagination: { offset: 0, limit: defaultLimit },
        filters,
      }));
    }

    setShowFilter(false);
  };

  // filter removed from top bar
  const removeFilterSelectionValue = (valueId) => {
    setProductsData([]);
    setShowLoading(true);

    setProductQueryParams((p) => {
      return {
        ...p,
        pagination: { offset: 0, limit: defaultLimit },
        filters: p.filters.filter((x) => x !== valueId),
      };
    });
  };

  // filters changed from sidebar
  const onApplyFilters = (valueId) => {
    setProductsData([]);
    setShowLoading(true);

    setProductQueryParams((p) => {
      const updatedFilters = p.filters.includes(valueId)
        ? p.filters.filter((x) => x !== valueId)
        : [...p.filters, valueId];

      return {
        ...p,
        pagination: { offset: 0, limit: defaultLimit },
        filters: updatedFilters,
      };
    });
    setShowFilter(false);
  };

  // filters cleared from top bar
  const clearFilters = () => {
    setProductsData([]);
    setShowLoading(true);

    setProductQueryParams((p) => ({
      ...p,
      filters: [],
      pagination: { offset: 0, limit: defaultLimit },
    }));
  };

  const onCloseFilters = () => {
    setShowFilter(false);
  };

  const showFilterModel = () => {
    setShowFilter(!isShowFilter);
  };

  const handleLoadMore = () => {
    if (productsData === null || productsData.length >= productsTotal) return;

    setLoadingMore(true);
    setProductQueryParams((p) => {
      const newPaginationLimit = p.pagination.offset + p.pagination.limit;
      return {
        ...p,
        pagination: { offset: newPaginationLimit, limit: defaultLimit },
      };
    });
  };

  useEffect(() => {
    if (productService === null) return;
    productService.next(productQueryParams);
  }, [productQueryParams]);

  useEffect(() => {
    LogBox.ignoreLogs(["VirtualizedLists should never be nested"])

    const ipcCategoryId = route?.params?.id;
    if (ipcCategoryId === undefined) return;

    setCategoryName(getName(route.params.category));
    setCategorySlug(route.params.category);

    // todo: immediately unsubscribe?
    const filterService = queryManager.filters(ipcCategoryId);
    const filterSubscription = filterService.out.subscribe(({ results }) => {
      setFilterFacets(results.filters);

      const supportedSortOptions = results.sorts
        .map((item) => {
          return [
            {
              attributeId: item.attributeId,
              label: `${getName(item.label)} - ${item.attributeType === "number" ? "Low to High" : "A to Z"}`,
              order: 0,
              value: `0.${item.attributeId}`,
            },
            {
              attributeId: item.attributeId,
              label: `${getName(item.label)} - ${item.attributeType === "number" ? "High to Low" : "Z to A"}`,
              order: 1,
              value: `1.${item.attributeId}`,
            },
          ];
        })
        .flat();

      setSortOptions([...defaultSortOptions, ...supportedSortOptions]);
    });
    filterService.next();

    const productService = queryManager.products(ipcCategoryId);
    const productSubscription = productService.products.subscribe(({ results, timeTaken }) => {
      setShowLoading(false);
      setLoadingMore(false);
      setDuration(timeTaken);

      setProductsData((p) => {
        const prevProductsData = p === null ? [] : p;
        return [...prevProductsData, ...results];
      });
    });

    const productTotalsSubscription = productService.totals.subscribe(({ results }) => {
      if (results.total) setProductsTotal(results.total);
      if (results.products) {
        setProductsData((oldProductsData) => {
          return oldProductsData.map((p) => {
            if (results.products.has(p.pid)) return results.products.get(p.pid);
            return p;
          });
        });
      }
    });
    productService.next(productQueryParams);

    setProductService(productService);

    return () => {
      filterSubscription.unsubscribe();
      filterService.destroy();
      productSubscription.unsubscribe();
      productTotalsSubscription.unsubscribe();
      productService.destroy();
      setProductService(null);
      setProductsData(null);
      setFilterFacets(null);
      if (selectedSort.touched) updateSortValue(defaultSortOptions[0]);
    };
  }, []);

  useEffect(() => {
    let windowHeight = Dimensions.get("window").height;
    setWindowHeight(windowHeight - 65);
  }, [windowHeight, Dimensions.get("window").height, Dimensions.get("window").width]);

  const getTagName = (valueId) => {
    return filters
      .map((x) => x.options)
      .flat()
      .find((x) => x.valueId === valueId).label;
  };

  const viewProductDetails = (categorySlug, productName, productId) => {
    if (categorySlug) {
      navigation.push("product-detail-ipc", {
        category: categorySlug,
        categoryId: route?.params?.id,
        name: formatNametoUrl(productName),
        id: productId,
      });
    } else {
      navigation.push("product-detail-ipc", { name: formatNametoUrl(productName), id: productId });
    }
  };

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = windowHeight;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const scrollToTop = () => {
    scrollviewRef.current.scrollTo({ y: 0, animated: isMediumScreen ? true : false });
  };

  return (
    <>
      <Header navigation={navigation} route={route} />

      <ScrollView
        style={styles.scrollContainerStyle}
        ref={scrollviewRef}
        onScroll={({ nativeEvent }) => {
          setContentVerticalOffset(nativeEvent?.contentOffset?.y);
          if (isCloseToBottom(nativeEvent)) {
            handleLoadMore();
          }
        }}
        scrollEventThrottle={200}
      >
        <Box style={styles.gridContainer} my={3} minHeight={windowHeight}>
          {/* top row - header, Response time, sort and filter starts */}
          <HStack justifyContent="space-between" style={styles.titleRow} position="relative">
            <Heading size="lg" maxWidth="60%" textAlign={{ base: "center", lg: "left" }}>
              {categoryName || "Results"}
            </Heading>

            {!isMediumScreen && !showSidebarFilterUI && (
              <Button.Group isAttached={true} isDisabled={filters?.length < 1}>
                <Menu
                  pt="0"
                  trigger={(triggerProps) => {
                    return (
                      <Button
                        size="sm"
                        pl={3}
                        {...triggerProps}
                        colorScheme={selectedSort.touched ? "default" : "primary"}
                        rightIcon={<Icon as={<MaterialIcons name="sort" />} size="5" />}
                        mr="1px"
                        borderRightRadius="0"
                      >
                        SORT BY
                      </Button>
                    );
                  }}
                >
                  <Menu.OptionGroup
                    title="Sort By"
                    type="radio"
                    value={productQueryParams.sort.value}
                    onChange={(val) => {
                      updateSortValue(val);
                    }}
                  >
                    {sortOptions.map((item) => (
                      <Menu.ItemOption key={item.value} onPress={() => updateSortValue(item)} value={item.value}>
                        {item.label}
                      </Menu.ItemOption>
                    ))}
                  </Menu.OptionGroup>
                </Menu>
                <Button
                  size="sm"
                  colorScheme="primary"
                  rightIcon={<Icon as={<MaterialIcons name="filter-alt" />} size="5" />}
                  onPress={showFilterModel}
                >
                  FILTER BY
                </Button>
              </Button.Group>
            )}
          </HStack>
          {/* top row - header, Response time, sort and filter ends */}

          <HStack justifyContent="space-between">
            {/* sidebar sort and filter starts */}
            {!isMediumScreen && showSidebarFilterUI && (
              <Box w={225} mr={5}>
                {/* filters placeholder */}
                {!filters &&
                  Array(10)
                    .fill(0)
                    .map((_, i) => <Skeleton key={i} variant="text" mb={2} height={35} />)}
                {/* filters placeholders */}

                {filters && (
                  <FiltersSidebarSQL
                    filters={filters}
                    appliedFilters={productQueryParams.filters}
                    onApplyFilterClick={onApplyFilters}
                    ipcSortOptions={sortOptions}
                  />
                )}
              </Box>
            )}
            {/* sidebar sort and filter ends */}

            {/* product listing container */}
            <Box flex={1}>
              {/* filter seletion row starts */}
              {productQueryParams.filters.length > 0 && (
                <Box>
                  <HStack justifyContent="space-between" mb={3} alignItems="center">
                    <ScrollView horizontal={true}>
                      {productQueryParams.filters.map((item, index) => {
                        return (
                          <Tag key={index} colorScheme="primary" rounded={5} mr={2} py={0}>
                            {getTagName(item)}
                            <IconButton
                              size="sm"
                              colorScheme="default"
                              marginRight={-2}
                              variant={"unstyled"}
                              onPress={() => {
                                removeFilterSelectionValue(item);
                              }}
                              icon={<>{<Icon size="xs" as={<MaterialIcons name="close" />} />}</>}
                            />
                          </Tag>
                        );
                      })}
                    </ScrollView>
                    <Button variant={"link"} size="xs" onPress={() => clearFilters()}>
                      <Text lineHeight={17}>Clear All ({productQueryParams.filters.length})</Text>
                    </Button>
                  </HStack>
                </Box>
              )}
              {/* filter seletion row ends */}

              {/* result count details starts */}
              {!showLoading && productsData && productsData?.length > 0 && (
                <Text mb={5}>
                  Showing
                  <Text fontWeight="bold"> {productsData?.length} </Text>
                  result/s<Text>{productsTotal !== undefined ? " out of " : ""}</Text>
                  <Text fontWeight="bold">{productsTotal !== undefined ? productsTotal : ""}</Text>
                  <Text>{categoryName ? ` for "${categoryName}"` : ""}</Text>
                </Text>
              )}
              {/* result count details ends */}

              {/* no results info starts */}
              {!showLoading && productsData && productsData?.length < 1 && <Text>No data</Text>}
              {/* no results info ends */}

              {/* Mobile: Product listing container starts */}
              {!showLoading && productsData && productsData.length > 0 && isMediumScreen && (
                <FlatList
                  numColumns={1}
                  removeClippedSubviews
                  data={productsData}
                  scrollEnabled={false}
                  renderItem={({ item }) => (
                    <Box mb={2}>
                      <TouchableHighlight
                        underlayColor={brandTheme.light}
                        onPress={() => viewProductDetails(categorySlug, item.name, item.pid)}
                      >
                        <ProductItemMobile product={item} />
                      </TouchableHighlight>
                    </Box>
                  )}
                  keyExtractor={(item) => item.pid}
                />
              )}
              {/* Mobile: Product listing container ends */}

              {/* Desktop: Product listing container starts */}
              {!showLoading && productsData && productsData.length > 0 && !isMediumScreen && (
                <SimpleGrid
                  minChildWidth={{ base: "50%", sm: 200, lg: "25%" }}
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  spacing={0}
                  style={styles.gridRow}
                >
                  {productsData?.map((product) => {
                    return (
                      <TouchableHighlight
                        key={product.pid}
                        underlayColor={brandTheme.light}
                        onPress={() => viewProductDetails(categorySlug, product.name, product.pid)}
                      >
                        <ProductItemDesktop product={product} />
                      </TouchableHighlight>
                    );
                  })}
                </SimpleGrid>
              )}
              {/* Desktop: Product listing container ends */}

              {/* loading placeholders starts */}
              {(showLoading || loadingMore) && (
                <>
                  {!loadingMore && <Skeleton variant="text" height={22} width="50%" mb={5} />}
                  {!isMediumScreen && (
                    <SimpleGrid
                      minChildWidth={{ base: "50%", sm: 200, lg: "25%" }}
                      justifyContent={{ base: "center", lg: "flex-start" }}
                      spacing={0}
                      style={styles.gridRow}
                    >
                      {Array(defaultLimit)
                        .fill(0)
                        .map((_, i) => (
                          <ProductItemDesktopSkeleton key={i} />
                        ))}
                    </SimpleGrid>
                  )}
                  {isMediumScreen && (
                    <Box>
                      {Array(defaultLimit)
                        .fill(0)
                        .map((_, i) => (
                          <ProductItemMobileSkeleton key={i} />
                        ))}
                    </Box>
                  )}
                </>
              )}
              {/* loading placeholders ends*/}
            </Box>
          </HStack>
        </Box>
        <Footer navigation={navigation} />
      </ScrollView>

      {/* Mobile: sticky bottom nav */}
      <MobilieStickyNav navigation={navigation} route={route} />

      {/* Mobile: Filter toggle buton and modal starts */}
      {isMediumScreen && (
        <IconButton
          rounded={50}
          variant="solid"
          position="absolute"
          bottom={57}
          right={2}
          size="lg"
          shadow={5}
          isDisabled={showLoading}
          icon={<Icon color="white" as={<MaterialIcons name="filter-alt" />} size="sm" />}
          onPress={showFilterModel}
        />
      )}

      <Modal isOpen={isShowFilter} onClose={onCloseFilters} useNativeDriver={true}>
        <Modal.Content
          width={["full", "full", "70%", "90%"]}
          rounded="0"
          height={["full", "full", "80%", "80%"]}
          marginTop={["0", "0", "5%"]}
          style={styles.filterModalUI}
        >
          <IconButton
            size="md"
            style={styles.modalCloseBtn}
            _hover={brandTheme.defaultHover}
            _pressed={brandTheme.defaultPressed}
            onPress={onCloseFilters}
            icon={<>{<Icon size="8" color={brandTheme.inputIcon} as={<MaterialIcons name="close" />} />}</>}
          />
          {filters && (
            <FiltersSQL
              filters={filters}
              appliedFilters={productQueryParams.filters}
              updateFilterAndSort={updateFilterAndSort}
              ipcSortOptions={sortOptions}
            />
          )}
        </Modal.Content>
      </Modal>
      {/* Mobile: Filter toggle buton and modal ends */}

      {/* Scroll to Top button starts */}
      { Platform.OS === 'web' && 
        <Stagger
          visible={contentVerticalOffset > CONTENT_OFFSET_THRESHOLD}
          initial={{
            opacity: 0,
            bottom: 0,
          }}
          animate={{
            scale: 1,
            bottom: 30,
            transition: {
              type: "spring",
              mass: 0.8,
              stagger: {
                offset: 30,
                reverse: true,
              },
            },
          }}
          exit={{
            bottom: 0,
            opacity: 0,
            transition: {
              duration: 100,
              stagger: {
                offset: 30,
                reverse: true,
              },
            },
          }}
        >
          <IconButton
            rounded={50}
            variant="solid"
            position="absolute"
            bottom={27}
            right={isMediumScreen ? 16 : 2}
            size="lg"
            shadow={5}
            isDisabled={showLoading}
            icon={<Icon color="white" as={<MaterialIcons name="arrow-upward" />} size="sm" />}
            onPress={scrollToTop}
          />
        </Stagger>
      }
      {/* Scroll to Top button ends */}

      {/* show Response Time starts */}
      {duration && showResponseTime && (
        <Alert
          px={1}
          py={0}
          bottom={0}
          rounded={0}
          right={0}
          style={styles.responseTimeBox}
          status={getStatusColor(duration)}
        >
          <Text fontSize="sm">{duration} ms</Text>
        </Alert>
      )}
      {/* show Response Time starts */}
    </>
  );
};

export default ProductCategoryListingPageSQL;
