import React, { useEffect, useState } from "react";
import { View, Text, Image, Box } from "native-base";

/* config Import */
import { defaultVariables } from "../../../../config";

/*Common Functions & Styles*/
import styles from "../../../utils/styles";

/* Import Images */
import PlaceholderImage from "../../../../assets/img-placeholder.png";

const ProductItemDesktop = ({ product }) => {
  const getMappedPrice = (price, currency) => {
    if (price) {
      let priceCurrency = currency || defaultVariables.currency;
      return `${priceCurrency == "INR" ? "₹" : priceCurrency} ${parseFloat(price).toFixed(2)}`;
    }
    return false;
  };

  return (
    <Box rounded="lg" style={styles.column}>
      <Box style={styles.imgBoxContainer}>
        <View style={styles.imgBoxPlacholder}>
          <Image style={styles.imgBox} source={PlaceholderImage} alt={product.name} resizeMode="contain" />
        </View>
        {product.attributes.images && product.attributes.images.length > 0 && (
          <Image
            style={styles.imgBox}
            source={{ uri: product.attributes.images[0] }}
            alt={product.name}
            resizeMode="contain"
          />
        )}
      </Box>
      <Text textAlign="center" width={[155, 200]} mx="auto" noOfLines={2} lineHeight={17} px={2}>
        {product.name}
      </Text>
      <Text textAlign="center" fontSize={30}>
        {(product.attributes.price &&
          product.attributes.price.length > 0 &&
          getMappedPrice(product.attributes.price[0])) ||
          ""}
      </Text>
    </Box>
  );
};

export default ProductItemDesktop;
