import React, { useEffect } from 'react';
import { useToast, Box } from 'native-base';

import { useSelector } from 'react-redux'

const AlertUI = ({removeAlert}) => {
  //const [open, setOpen] = React.useState(false);
  const alertData = useSelector(state => state.alert.alertData)
  //const isAlertShown = useSelector(state => state.alert.isAlertShown)
  const toast = useToast()

  useEffect(() => {
    if(alertData){
      toast.show({
        title: alertData.msg,
        status: alertData.alertType,
        duration: alertData.timeout
      })
    }

  }, [alertData])

  return (
    <Box mr={ 10 }></Box>
  );
};

export default AlertUI;
