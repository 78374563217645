/* Default Imports */
import React, { useEffect, useState, useRef } from "react";
import { Modal, SimpleGrid, Skeleton, Text, Image, ScrollView, Box, Heading, Button, HStack, Alert, Icon, Menu, IconButton, Tag, useMediaQuery, FlatList, VStack, Stagger} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { Dimensions, TouchableHighlight, Platform, LogBox } from 'react-native';

/* Store */
import { useSelector, useDispatch } from 'react-redux'
import { updateSortBy } from '../store/actions/appState';

/*GrapghQL*/
import { useLazyQuery } from "@apollo/client";
import { defaultVariables, QUERY_PRODUCTS_BY_CATEGORY_FILTER, QUERY_GET_PRODUCTS } from "../apis/graphql";

/*Common Functions & Styles*/
import { getName, formatNametoUrl, getPrice, ctSortOptions, getStatusColor} from "../utils/commonFunctions"
import styles, { brandTheme } from "../utils/styles";

/* Components */
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobilieStickyNav from "../components/MobilieStickyNav";
import FiltersCT from "../components/commomAssets/filters/Filters-CT";
import FiltersSidebarCT from "../components/commomAssets/filters/FiltersSidebar-CT";
import ProductItemDesktopSkeleton from "../components/commomAssets/skeletons/ProductItemDesktopSkeleton";
import ProductItemMobileSkeleton from "../components/commomAssets/skeletons/ProductItemMobileSkeleton";

/* Import Images */
import PlaceholderImage from "../../assets/img-placeholder.png"

const loadingCount = 12

const ProductCategoryListing = ({ navigation, route }) => {
  const queryVariables = defaultVariables;  
  const [loadData, { data }] = (route.params?.id)?useLazyQuery(QUERY_PRODUCTS_BY_CATEGORY_FILTER, {fetchPolicy: "no-cache",nextFetchPolicy: "no-cache"}) : useLazyQuery(QUERY_GET_PRODUCTS, { variables: queryVariables, fetchPolicy: "no-cache",nextFetchPolicy: "no-cache" });
  
  const [ firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [ productTypeFacets, setProductTypeFacets] = useState(null);
  const [ showLoading, setShowLoading ] = useState(true);
  const [ isShowFilter, setShowFilter] = useState(false);
  const [isMediumScreen] = useMediaQuery({ maxWidth:767 });
  const [filterAppliedValue, setFilterAppliedValue] = useState([])
  const [filterRefreshed, setFilterRefreshed] = useState(false)
  const [productsData, setProductsData] = useState(null)
  const [categoryName, setCategoryName] = useState(null)
  const [categorySlug, setCategorySlug] = useState(null)
  const [windowHeight, setWindowHeight] = useState(null)
  const [searchOffset, setSearchOffset] = useState(0)
  const [searchLimit, setSearchLimit] = useState(20)
  const [selectedSort, setSelectedSort] = useState("")
  const [duration, setDuration] = useState(null)
  const [loadingMore, setLoadingMore] = useState(false)
  const [apiTriggerisInProgress, setApiTriggerisInProgress] = useState(false)

  const allProductTypes = useSelector(state => state.appData.productTypes)
  const showSidebarFilterUI = useSelector(state => state.appData.viewConfig.showSidebarFilterUI)
  const showResponseTime = useSelector(state => state.appData.viewConfig.showResponseTime)
  const sortValue = useSelector(state => state.appData.sortBy)

  /* common functions */
  const scrollviewRef = useRef(null);
  const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
  const CONTENT_OFFSET_THRESHOLD = windowHeight/3;

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = windowHeight;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  const removeFilterSelectionValue = (value ) => {
    let existingValues = filterAppliedValue
    let updatedValues = existingValues.filter(item => item !== value)
    setFilterRefreshed(false)
    setSearchOffset(0)
    setShowLoading(true)
    setProductsData(null)
    setFilterAppliedValue(updatedValues)
  }

  const onApplyFilters = (values,sort) =>{
    if(selectedSort && !firstTimeLoad && sort !==selectedSort && JSON.stringify(filterAppliedValue) === JSON.stringify(values)){
      if((selectedSort !== "" || selectedSort !== "relevance" || sort !==selectedSort)){
        setSelectedSort(sort)
        refreshPagewithFilters()
      }
    }
    else if(!firstTimeLoad && JSON.stringify(filterAppliedValue) !== JSON.stringify(values)){
      setFilterRefreshed(false)
      setSearchOffset(0)
      setShowLoading(true)
      setProductsData(null)
      setFilterAppliedValue(values)
      if(sort !==selectedSort){setSelectedSort(sort)}
      if((selectedSort !== "" || selectedSort !== "relevance") && values.length === 0){
        refreshPagewithFilters()
      }
    }
    
    setShowFilter(false)
  }

  const onClearFilters = () =>{
    setFilterRefreshed(false)
    setSearchOffset(0)
    setShowLoading(true)
    setProductsData(null)
    setFilterAppliedValue([])
    setShowFilter(false)
  }

  const clearFilters = () => {
    setFilterRefreshed(false)
    setSearchOffset(0)
    setShowLoading(true)
    setProductsData(null)
    setFilterAppliedValue([])
    setShowFilter(false)
  }

  const onCloseFilters = () => {
    setShowFilter(false)
  }

  const showFilterModel = () => {
    setShowFilter(!isShowFilter)
  }
 
  const dispatch = useDispatch()

  const updateSortValue = (value) => {
    setSelectedSort(value)
    dispatch(updateSortBy(value))
  }

  const handleLoadMore = () => {
    if(productsData?.results?.length<productsData?.total){
      if(!apiTriggerisInProgress){
        setApiTriggerisInProgress(true)
        setLoadingMore(true)
        setSearchOffset(searchOffset+searchLimit)
      }
    }
  }

  const scrollToTop = () => {
      scrollviewRef.current.scrollTo({ y: 0, animated: isMediumScreen? true : false })
  }

  const viewProductDetails = (categorySlug,productName,productId) => {
    if(categorySlug || route?.params?.category){
      navigation.push("product-detail", {category: route?.params?.category, categoryId: route?.params?.id, name: formatNametoUrl(productName), id:productId })
    }else{
      navigation.push("product-detail", {name: formatNametoUrl(productName), id:productId })
    }
  }

  const refreshPagewithSortValue = (sortValue) => {
    if(selectedSort !== sortValue){
      setSelectedSort(sortValue)
    }
  }

  {/* get products on updated sort / filter values starts */}
  
  const refreshPagewithFilters = () => {
    let filterQueryRaw = []
    filterAppliedValue.forEach( item => {
        const queryItem = {
          model: {
          value: {
            path: item.split(':')[0],
            values: [item.split(':')[1]]
            }
          }
        };
        filterQueryRaw.push(queryItem)
      }
    )

    let filterQuery = filterQueryRaw.reduce((a, { model }) => {
        let item = a.find(el => el.model.value.path === model.value.path);
      if(!item) return [...a, {model}];
      item.model.value.values.push(model.value.values[0])
      return a;
    },[])

    filterQuery = [...filterQuery, { model: { tree: { path: "categories.id", rootValues: route?.params?.id, subTreeValues: route?.params?.id } } }]
    Object.assign(queryVariables, { 'filterQueries': filterQuery})

    var sortQuery;
    let updatedQueryVariables = {...queryVariables}
    if(sortValue !== "" && sortValue !== "relevance" && selectedSort !== "" && selectedSort !== "relevance"){
      sortQuery = {sorts: sortValue}
      Object.assign(updatedQueryVariables, {sorts: sortValue})
    }
    else{
      updatedQueryVariables = Object.keys(updatedQueryVariables)
                              .filter(key => key !== 'sorts')
                              .reduce((obj, key) =>{
                                    obj[key] = updatedQueryVariables[key];
                                    return obj;
                                }, {}
                              )
    }

    if(!firstTimeLoad){
      if (!route.params?.id) loadData({ variables: updatedQueryVariables, offset: searchOffset, limit: searchLimit })
      else loadData({ variables: { ...updatedQueryVariables, categoryId: route?.params?.id, offset: searchOffset, limit: searchLimit } });
    }
  }
  {/* get products on updated sort / filter values ends*/}

  //  Setting up Dimentions
  useEffect(() => {
    let windowHeight = Dimensions.get('window').height;
    setWindowHeight((windowHeight-65))
  }, [windowHeight, Dimensions.get('window').height, Dimensions.get('window').width])

  {/* Hooks for handling updates starts */}
  useEffect( () => {
    if(loadingMore){
      refreshPagewithFilters()
    }
  }, [searchOffset])

  useEffect(()=>{
    if(selectedSort &&(selectedSort !== "" || selectedSort !== "relevance") && !firstTimeLoad){
      setSearchOffset(0)
      setShowLoading(true)
      setProductsData(null)
      refreshPagewithFilters()
    }
  },[selectedSort])

  useEffect(() => {
    if(data?.productProjectionSearch){
      setDuration(`${data.responsetime} ms`)
      let prodData = {results:[], total:0}
      prodData.results = data?.productProjectionSearch?.results
      prodData.total = data?.productProjectionSearch?.total
      setShowLoading(false)
      if(loadingMore){
        let updatedData = productsData;
        updatedData.results = [...productsData.results,  ...prodData.results]
        setProductsData(updatedData);
        setLoadingMore(false)
        setApiTriggerisInProgress(false)
      }
      else{
        setProductsData(prodData);
      }
      
      setProductTypeFacets(data?.productProjectionSearch?.facets)
      setFirstTimeLoad(false)
    }
  }, [data]);
    {/* Hooks for handling updates ends */}

  {/* Initial Product Listing Data loading */}
  useEffect(() => {
    if(!firstTimeLoad){
      refreshPagewithFilters()
      setFilterRefreshed(true)
    }
  }, [filterAppliedValue])

  {/* Initial Product Listing Data loading */}
  useEffect(() => {
    LogBox.ignoreLogs(["VirtualizedLists should never be nested"])
    let mounted = true;
    if(mounted){
      setWindowHeight(Dimensions?.get('window').height-65)
      setCategoryName((route.params?.id)?getName(route.params.category) : 'Our Collections');
      setCategorySlug((route.params?.id)?route.params.category : '')
      setShowLoading(true)
      setProductsData(null)

      let filterQuery = [{ model: { tree: { path: "categories.id", rootValues: route?.params?.id, subTreeValues: route?.params?.id } } }]
      let defaultQueryVariables = queryVariables;
      Object.assign(defaultQueryVariables, { 'filterQueries': filterQuery})

      if (!route.params?.id) {
        loadData({ variables: {defaultQueryVariables, limit: searchLimit} });
      }
      else {
        loadData({ variables: { ...defaultQueryVariables, categoryId: route?.params?.id, offset: searchOffset, limit: searchLimit } });
      }
    }
    return () => {
      mounted = false;
    }
  }, []);

  {/* Mobile: Flatlist Render Item */}
  const renderItem = ({ item }) => (
    <Box mb={2}>
      <TouchableHighlight underlayColor={brandTheme.light} onPress={() => viewProductDetails(categorySlug,item.name,item.id)}>
        <HStack space={3}>
            <Box w="40%">
              {!(item?.masterVariant?.images[0]?.url) && <Image
                    style={styles.imgBoxMobileContainer}
                    source={ PlaceholderImage}
                    alt={item?.name}
                    resizeMode="contain"
                  />
              }
              {item?.masterVariant?.images[0]?.url && <Image
                style={styles.imgBoxMobileContainer}
                source={ { uri: item?.masterVariant?.images[0]?.url } }
                alt={item?.name}
                resizeMode="contain"
              />}
            </Box>
            <VStack flex={1}>
              <Text fontSize={17} noOfLines={2} lineHeight={22}>{item?.name}</Text>
              <Text fontSize={27}>{getPrice(item?.masterVariant.price)}</Text>
            </VStack>
          </HStack>
        </TouchableHighlight>
      </Box>
  )

  return (
    <>
      <Header navigation={navigation} route={route}/>
      <ScrollView style={styles.scrollContainerStyle}
        ref={scrollviewRef}
        onScroll={({nativeEvent}) => {
          setContentVerticalOffset(nativeEvent?.contentOffset?.y);
          if (isCloseToBottom(nativeEvent)) {
              handleLoadMore()
          }
        }}
        scrollEventThrottle={200}
      >
        <Box style={styles.gridContainer} my={3} minHeight={windowHeight}>
          {/* top row - header, Response time, sort and filter starts */}
          <HStack justifyContent="space-between" style={styles.titleRow} position="relative">
            <Heading size="lg" maxWidth="60%" textAlign={{base:"center", lg:"left"}} pr={2} >{ categoryName || "Results" }</Heading>

            {!isMediumScreen && !showSidebarFilterUI && 
              <Button.Group isAttached={true}>
                <Menu pt="0"
                  trigger={(triggerProps) => {
                    return (
                      <Button size="sm" pl={3} {...triggerProps} colorScheme={sortValue?"default" : "primary"} rightIcon={<Icon as={<MaterialIcons name="sort" />} size="5" />} mr="1px" borderRightRadius="0">SORT BY</Button>
                    )
                  }}
                >
                  <Menu.OptionGroup title="Sort By" type="radio" value={sortValue} onChange={(val) => {updateSortValue(val) }}>
                    { ctSortOptions.map( (item,key) => (<Menu.ItemOption key={key} onPress={()=>updateSortValue(item.value)} value={item.value}>{item.label}</Menu.ItemOption>))}
                  </Menu.OptionGroup>
                </Menu>
                <Button size="sm" colorScheme="primary" rightIcon={<Icon as={<MaterialIcons name="filter-alt" />} size="5" />} onPress={showFilterModel} disabled={!productTypeFacets || allProductTypes.length<1}>FILTER BY</Button>
              </Button.Group>
            }
          </HStack>
          {/* top row - header, Response time, sort and filter ends */}

          <HStack justifyContent="space-between">
            {/* sidebar sort and filter starts */}
            {!isMediumScreen && showSidebarFilterUI && 
              <Box w={225} mr={5}>
                {/* filters placeholder */}
                { !productTypeFacets && Array(10).fill(0).map((_, i)=> <Skeleton key={i} variant="text" mb={2} height={35} />)}
                {/* filters placeholders */}

                {productTypeFacets && <FiltersSidebarCT categoryId={route?.params?.id} facets={productTypeFacets} appliedFilters={filterAppliedValue} onApplyFilterClick={onApplyFilters} isShowFilter={isShowFilter} filterRefreshed={filterRefreshed} refreshPagewithSort={refreshPagewithSortValue}/>}
              </Box>
            }
            {/* sidebar sort and filter ends */}

            {/* product listing container */}
            <Box flex={1}>
              {/* filter seletion row starts */}
              { filterAppliedValue?.length>0 && 
                <Box>
                  <HStack justifyContent="space-between" mb={3} alignItems="center">
                      <ScrollView horizontal={true}>
                        { filterAppliedValue.map( (item,index) => {
                          return(
                            <Tag key={index} colorScheme="primary" rounded={5} mr={2} py={0}>
                              { item.split(':')[1]}
                              <IconButton size="sm"
                              colorScheme="default"
                              marginRight={-2}
                              variant={"unstyled"}
                              onPress={() => { removeFilterSelectionValue(item) }}
                              icon={<>{<Icon size="xs" as={<MaterialIcons name="close" />}  />}</>}
                              />
                            </Tag>
                          )
                        })}
                      </ScrollView>
                      <Button variant={"link"} size="xs" onPress={ ()=> clearFilters()}>
                        <Text lineHeight={17}>Clear All ({filterAppliedValue?.length})</Text>
                      </Button>
                  </HStack>
                </Box>
              }
              {/* filter seletion row ends */}

              {/* result count details starts */}
              { !showLoading && productsData?.results?.length>0 && 
              <Text mb={5}>Showing  
                <Text fontWeight="bold"> { productsData?.results?.length } </Text> 
                result/s<Text>{ productsData?.total !== undefined ? " out of " : "" }</Text> 
                <Text fontWeight="bold">{ productsData?.total !== undefined ?  productsData?.total : "" }</Text>
                <Text>{ categoryName?` for "${categoryName}"`:'' }</Text>
              </Text>
              }
              {/* result count details ends */}

              {/* no results info starts */}
              { !showLoading && productsData?.results?.length < 1 && <Text>No data</Text>}
              {/* no results info ends */}
                  
              {/* Product listing container for Mobile starts */}
              { (!showLoading && productsData) && isMediumScreen && 
                <FlatList 
                    numColumns={1}
                    removeClippedSubviews
                    data={productsData?.results}
                    renderItem={renderItem}
                    scrollEnabled={false}
                    keyExtractor={(item) => item.id}
                />
              }
              {/* Product listing container for Mobile ends */}

              {/* Product listing container for Desktops starts */}
              { (!showLoading && productsData) && !isMediumScreen &&
                <SimpleGrid minChildWidth={{base:"50%", sm:200, lg:"25%"}} justifyContent={{base:"center", lg:"flex-start"}} spacing={0} style={styles.gridRow}>
                  {productsData?.results?.map((product, key) => {
                    return (
                      <TouchableHighlight key={key} underlayColor={brandTheme.light} mb={5} onPress={() => viewProductDetails(categorySlug,product.name,product.id)}>
                        <Box rounded="lg" style={styles.column}>
                          <Box style={styles.imgBoxContainer}>
                          {!(product?.masterVariant?.images[0]?.url) && 
                                <Image
                                  style={styles.imgBox}
                                  source={ PlaceholderImage}
                                  alt={product?.name}
                                  resizeMode="contain"
                                />
                            }
                            {product?.masterVariant?.images[0]?.url && <Image
                              style={styles.imgBox}
                              source={ { uri: product?.masterVariant?.images[0]?.url } }
                              alt={product?.name}
                              resizeMode="contain"
                            />}
                          </Box>
                          <Text textAlign="center" width={[155, 200]} mx="auto" noOfLines={2} lineHeight={17} px={2}>{product?.name}</Text>
                          <Text textAlign="center" fontSize={30}>{getPrice(product?.masterVariant.price)}</Text>
                        </Box>
                      </TouchableHighlight>
                    )
                  })}
              </SimpleGrid>
              }
              {/* Product listing container for Desktops ends */}

                            
              {/* loading placeholders starts */}
              { (showLoading || loadingMore) && 
                      <>
                        {!loadingMore && <Skeleton variant="text" height={22} width="50%" mb={5}/>}
                        { !isMediumScreen && 
                          <SimpleGrid minChildWidth={{base:"50%", sm:200, lg:"25%"}} justifyContent={{base:"center", lg:"flex-start"}} spacing={0} style={styles.gridRow}>
                              {
                                Array(loadingCount).fill(0).map((_, i)=><ProductItemDesktopSkeleton key={i} />)
                              }
                          </SimpleGrid>
                        }
                        { isMediumScreen && 
                          <Box>
                              {
                                Array(loadingCount).fill(0).map((_, i)=><ProductItemMobileSkeleton key={i} />)
                              }
                          </Box>
                        }
                      </>
              }
              {/* loading placeholders ends*/}
            </Box>
          </HStack>
        </Box>
        <Footer navigation={navigation}/>
      </ScrollView>
      
      {/* Mobile: sticky bottom nav */}
      <MobilieStickyNav navigation={navigation} route={route}/>

      {/* Mobile: Filter toggle buton and modal starts */}
      {isMediumScreen && productTypeFacets && allProductTypes.length>0 && <IconButton
          rounded={50}
          variant="solid"
          position="absolute"
          bottom={57}
          right={2}
          size="lg"
          shadow={5}
          isDisabled={showLoading}
          icon={<Icon color="white" as={<MaterialIcons name="filter-alt" />} size="sm" />}
          onPress={showFilterModel}
        />
      }

      <Modal isOpen={isShowFilter} onClose={onCloseFilters} useNativeDriver={true}>
        <Modal.Content width={['full', 'full', '70%', '90%']} rounded="0" height={['full', 'full', '80%', '80%']} marginTop={['0', '0', '5%']} style={styles.filterModalUI}>
          <IconButton size="md" style={ styles.modalCloseBtn } _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
              onPress={onCloseFilters}
              icon={<>{<Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="close" />}
              />}</>}
          /> 
          {productTypeFacets && 
            <FiltersCT categoryId={route?.params?.id} facets={productTypeFacets} appliedFilters={filterAppliedValue} onApplyFilterClick={onApplyFilters} onClearFilterClick={onClearFilters} filterRefreshed={filterRefreshed} />
          }
        </Modal.Content>
      </Modal>
      {/* Mobile: Filter toggle buton and modal starts */}

      {/* Scroll to Top button starts */}
      { Platform.OS === 'web' && 
        <Stagger
            visible={contentVerticalOffset > CONTENT_OFFSET_THRESHOLD}
            initial={{
              opacity: 0,
              bottom:0
            }}
            animate={{
              scale: 1,
              bottom: 30,
              transition: {
                type: "spring",
                mass: 0.8,
                stagger: {
                  offset: 30,
                  reverse: true,
                },
              },
            }}
            exit={{
              bottom: 0,
              opacity: 0,
              transition: {
                duration: 100,
                stagger: {
                  offset: 30,
                  reverse: true,
                },
              },
            }}
          ><IconButton
            rounded={50}
            variant="solid"
            position="absolute"
            bottom={27}
            right={ isMediumScreen ? 16 : 2}
            size="lg"
            shadow={5}
            isDisabled={showLoading}
            icon={<Icon color="white" as={<MaterialIcons name="arrow-upward" />} size="sm" />}
            onPress={scrollToTop}
          />
        </Stagger>
      }
      {/* Scroll to Top button ends */}

      {/* show Response Time starts */}
      {duration && showResponseTime && <Alert px={1} py={0} bottom={0} rounded={0} right={0} style={styles.responseTimeBox} status={getStatusColor(duration)}>
            <Text fontSize="sm">{duration}</Text>
      </Alert>}
      {/* show Response Time starts */}
    </>
  );
}

export default ProductCategoryListing;