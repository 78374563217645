// commercetools project key
const projectKey = "nvizion-internal";

const scopes = [
  "view_published_products",
  "view_products",
  "view_categories",
  "manage_my_orders",
  "manage_my_shopping_lists",
  "manage_my_profile",
  "manage_my_payments",
  "create_anonymous_token",
  "view_types",
].map((x) => `${x}:${projectKey}`);

export const config = {
  ct: {
    auth: {
      host: "https://auth.europe-west1.gcp.commercetools.com",
      projectKey,
      disableRefreshToken: false,
      credentials: {
        // clientId: "kZJbGRhJ9ngl4QJsWm-o9Ybc",
        // clientSecret: "gF0UUvgDCYX8AN33qCnFXhfFd6kIQrfK",
        clientId: "XYN-3N5Cykz5XMZB9fyDkk-d",
        clientSecret: "N11NVmdzWwZEcMrgyivuz3PrXBwdNUwg",
      },
      scopes,
    },
    api: "https://api.europe-west1.gcp.commercetools.com",
  },
  languages: {
    en: "English",
  },
  countries: {
    IN: "India",
  },
  formats: {
    number: {
      IN: {
        currency: {
          style: "currency",
          currency: "INR",
        },
      },
    },
    datetime: {
      IN: {
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
      },
    },
  },
  categories: {
    salesExternalId: "6",
  },
  facetSearches: [
    {
      name: "size",
      type: "enum",
      label: {
        en: "Size",
      },
    },
    {
      name: "swatchColors",
      type: "text",
      label: {
        en: "Swatch Colors",
      },
    },
    {
      name: "color",
      type: "text",
      label: {
        en: "Color",
      },
    },
    {
      name: "brand",
      type: "text",
      label: {
        en: "Brands",
      },
    },
  ],
  detailAttributes: [
    "designer",
    "colorFreeDefinition",
    "size",
    "gender",
    "articleNumberManufacturer",
    "brand",
    "color",
    "fabric-note",
    "style-note",
    "sleeve-length",
    "fit",
    "fabric",
    "type",
    "orientation-type",
    "colour",
    "material",
    "design",
    "compartments",
    "pocket-specifications",
    "water-resistance",
    "laptop-compatible",
    "style",
    "cap-size",
    "wash-care",
    "book-size",
    "no-pages",
    "paper",
    "binding",
    "page-style",
  ],
  variantSelector: ["color", "size"],
  variantInProductName: ["size"],
};

export const defaultVariables = {
  locale: "en",
  currency: "INR",
  country: "IN",
  channelId: "710d8a3e-552f-49d7-87b6-c2a274baec4a",
};

// LnaguageOptions
export const langOptions = [
  { label: "IN-English", value: "in-en", img: require("./assets/icons/flags/india.png") },
  { label: "US-English", value: "us-en", img: require("./assets/icons/flags/usa.png") },
];

/* View options */
export const viewConfig = {
  showFilterCount: false,
  showSidebarFilterUI: true,
  enableIPCSearch: false,
  showResponseTime: true,
};

export const IPC_CONFIG = {
  accountId: "9LM6AV",
  ctCatsToPreload: ["2f3cd6e1-29da-4f74-8461-abf43c2590c5"],
  domain: "hydra.n7.io",
};
