import { ApolloProvider } from "@apollo/client";

import React, { useState, useEffect } from "react";
import { NativeBaseProvider, Center, Spinner } from "native-base";

// Store
import { Provider } from "react-redux";
import store from "./src/store/store";
import AsyncStorage from "@react-native-async-storage/async-storage";

import createApolloClient from "./src/apis/createApolloClient";
import Root from "./src/Root";
//import { DataService } from "./src/db/db"; //Ramit's changes commented

import AlertUI from "./src/components/commomAssets/AlertUI";

export default function App() {
  const [apolloClient, setApolloClient] = useState(null);

  useEffect(() => {
    createApolloClient().then((c) => setApolloClient(c));
  }, []);

  return (apolloClient) ? (
    <NativeBaseProvider>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <Root />
          <AlertUI/>
        </Provider>
      </ApolloProvider>
    </NativeBaseProvider>
  ) : (
    <NativeBaseProvider>
      <Center flex={1}>
        <Spinner size="lg" accessibilityLabel="Loading..." />
      </Center>
    </NativeBaseProvider>
  );
}
