import React, { memo } from 'react';
import { Box, useMediaQuery } from "native-base";
import { brandTheme } from '../utils/styles';
import { Platform } from "react-native"

const Footer = ({ navigation }) => {
  const [isMediumScreen] = useMediaQuery({ maxWidth:767 });
  return (
    <>
      {Platform.OS === 'web' && !isMediumScreen && <Box alignItems="center" p={5} bg={brandTheme.dark}>&copy; 2021. Nvizion Storefront</Box>}
    </>
  );
}

export default memo(Footer);
