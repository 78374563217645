
/* config Import */
import { config, defaultVariables } from "../../config";


export const camelToCapitalize = (input) => {
  var text = input.replace( /([A-Z])/g, " $1" )
  return (text.charAt(0).toUpperCase() + text.slice(1)).trim()
};

export const capitalize = (input) => {
  return input
    ?.toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const getName = (input) => {
  return input
    ?.toLowerCase()
    .replace('-', ' ')
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const formatNametoUrl = (name) => name?.toLowerCase().replace(' ', '-');

export const getProductName = (name,lang,country) => {
  if(name){
    return String(name[`${lang}-${country}`])
  }
  else{
    return 'N/A'
  }
};

export const getPrice = (price) => {
  if(price){
    if(price?.value){
      const currency = price.value.currencyCode == "INR" ? "₹" : price.value.currencyCode;
      const Value = (price.value.centAmount / 100).toFixed(2);
      return `${currency} ${Value}`;
    }
    else{
      const currency = defaultVariables.currency == "INR" ? "₹" : '';
      const Value = Number(price).toFixed(2);
      return `${currency} ${Value}`;
    }
  }
  else{
    return 'N/A'
  }
};


export const percentage = (partialValue, totalValue) => {
  return (partialValue / 100)  * totalValue;
} 

/* PLp facets */
export const asAttribute = (name, type, locale) => {
  if (type === "lnum") {
    return `variants.attributes.${name}.label.${locale}`;
  }
  if (type === "enum") {
    return `variants.attributes.${name}.key`;
  }
  return `variants.attributes.${name}`;
};

export const getFilterfacets = (query, locale) => {
  config.facetSearches.reduce((result, { name, type }) => 
  {
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty(name)) {
        result["filter.query"] = result["filter.query"] || [];
        result["filter.query"].push(
          `${asAttribute(name, type, locale)}:${
            Array.isArray(query[name])
              ? query[name]
                  .map((value) => `"${value}"`)
                  .join(",")
              : `"${query[name]}"`
          }`
        );
      }
      return result;
    }, {})
  };

const totalFacets = config.facetSearches;
export const getConfigFacets = [
  //...Object.entries(facets(["size", "colors", "swatchColors", "brand"], "en")),
  ...totalFacets.map(({ name, type }) => {
    return {"facet" : `${asAttribute(name, type,"en")} counting products`}
  })]



export const getFilterData = (facetsData, locale, country) => {
  let rawFilters = config.facetSearches.map(
    ({ name, type, label }) => {
      const facet = facetsData[asAttribute(name, type, locale)];
        return {
        ...facet,
        name,
        label: label[locale] || name,
        type,
        terms: [
          ...(facet?.terms || []),
        ]
      };
    }
  );

  rawFilters = rawFilters.filter(item => item.terms.length>0);
  rawFilters.map(item=>{
    item.terms.map(subitem => {
      subitem.value = `variants.attributes.${item.name}:${subitem.term}`
    }
      )
  })
  return rawFilters
}

export const getStatusColor = (duration) => {
  const time = (duration instanceof String)? duration?.match(/\d+/)[0]: duration
  if(time<=100){
    return "success"
  }
  else if(time>100 && time<400){
    return "warning"
  }
  else{
    return "danger"
  }
}

export const ctSortOptions = [
      {value:"relevance", label:"Relevance"},
      {value:"price asc", label:"Price - Low to High"},
      {value:"price desc", label:"Price - High to Low"}
    ]