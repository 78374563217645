import React, { memo, useState, useEffect } from 'react';
import { Button, Menu, Flex, Icon } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

/* import sub componenets */
import FlagOption from "./FlagOption";
import { langOptions } from "../../../../config";
import { brandTheme } from '../../../utils/styles';

/* Store */
import { useDispatch } from 'react-redux'
import { selectLanguage } from '../../../store/actions/appState'

const FlagMenu = ({isSmallScreen}) => {
    const [language, setLanguage] = useState(langOptions[0])
    const [flagUpdate, setFlag] = useState(true)

    const dispatch = useDispatch()
  
    const updateLanguage = (item) => {
        dispatch(selectLanguage(item)).then(
            setFlag(false),
            setLanguage(item)
        )
    }
  
    useEffect(() => {
      setFlag(true)
    }, [language])

    return (
        <Menu placement="bottom right"
            trigger={(triggerProps) => {
            return (
                <Button size="sm" p="2" variant="outine" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} borderWidth="1px" borderColor="gray.100" {...triggerProps}>
                    <Flex direction="row" align="center" justify="center">
                        <FlagOption language={language} flagUpdate={flagUpdate} fontSize="12px" isSmallScreen={isSmallScreen }/>
                        <Icon as={<MaterialIcons name="arrow-downward" />}  size="xs"/>
                    </Flex>
                </Button>
            )
            }}
        >
            <Menu.OptionGroup title="Country-Language" value={language.value} type="radio" onChange={(val) => {setLanguage(val) }}>
                {langOptions?.map((item, key) => {
                    return (
                        <Menu.ItemOption key={key} value= { item.value } onPress={()=>updateLanguage(item)}>
                        <FlagOption language={item}  flagUpdate={true} fontSize="sm"/>
                        </Menu.ItemOption>
                    )})}
            </Menu.OptionGroup>
        </Menu>
    );
}

export default memo(FlagMenu);