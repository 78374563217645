import React, { memo } from 'react';
import { Skeleton, Box, HStack } from 'native-base';

/*Common Functions & Styles*/
import styles, {brandTheme} from '../../../utils/styles';

const ProductDetailsSkeleton = () => {
    return (
        <>
            <Box style={styles.gridContainer} my={5}>
                <Box display="flex" flexDirection={{base:"column", md:"row"}} justifyContent={"flex-start"} spacing={3} style={ styles.gridRow }>
                  <Box flex={1} width={["100%", "100%", "40%"]} height={ [ "100%", 400, 500 ]} style={ styles.column }>
                    <Skeleton my={2} height={6} variant="react" width={ '100%' }
                      height={ '100%'}
                      minHeight={ 300 }
                      borderWidth={1}
                      borderColor= { brandTheme.light} />
                  </Box>
                  <Box width={["100%", "100%", "60%"]} style={ styles.column }>
                    <Skeleton variant="text" height={25} mt={2}/>
                    <Skeleton variant="text" height={45} mt={2} />
                    <HStack space={4} my={3} alignItems="flex-start">
                        <Skeleton my={2} width={110} height={10} variant="text" />
                        <Skeleton my={2} width={110} height={10} variant="text" />
                    </HStack>

                    <Skeleton my={2} width={300} height={10} variant="text" />
                    <Box borderWidth={1} px={3} py={1} borderColor= { brandTheme.light}>
                        {Array(5).fill(0).map((_, i)=> {
                            return (
                                <Box key={i} display="flex" flexDirection={{base:"row"}} justifyContent={"flex-start"} spacing={3} style={ styles.gridRow }>
                                    <Box flex={1} width={["60%", "40%", "25%"]} style={ styles.column }><Skeleton variant="text" height={25} /></Box>
                                    <Box width={["60%", "60%", "75%"]} style={ styles.column }><Skeleton variant="text" height={25} /></Box>
                                </Box>)
                                }
                            )
                        }
                    </Box>
                  </Box>
                </Box>
            </Box>
        </>
    );
}

export default memo(ProductDetailsSkeleton);