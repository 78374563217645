import React, { memo } from 'react';
import { Skeleton, Box} from 'native-base';
import styles from '../../../utils/styles';

const ProductItemDesktopSkeleton = () => {
    return (
        <>
            <Box rounded="lg" style={styles.column}>
                <Box style={styles.imgBoxContainer}>
                    <Skeleton variant="react" style={styles.imgBox} />
                </Box>
                <Skeleton variant="text" height={22} mb={2} />
                <Skeleton variant="text" height={35} />
            </Box>
        </>
    );
}

export default memo(ProductItemDesktopSkeleton);