import React, { memo, useEffect, useState } from "react";
import { Box, Text, Heading, Menu, Button, Divider, Icon, FlatList } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableHighlight } from "react-native";

/* Store */
import { useSelector, useDispatch } from "react-redux";
import { updateSortByIpc } from "../../../store/actions/appState";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../../../utils/styles";
import { camelToCapitalize } from "../../../utils/commonFunctions";

const FiltersSidebarSQL = ({ filters, appliedFilters, onApplyFilterClick, ipcSortOptions }) => {
  const [isActiveTab, setActiveTab] = useState(null);
  const [sortedArray, setSortedArray] = useState([]);
  const [filterSelectionValue, setFilterSelectionValue] = useState([]);

  const showFilterCount = useSelector((state) => state.appData.viewConfig.showFilterCount);
  const selectedSort = useSelector((state) => state.appData.sortByIpc);

  const dispatch = useDispatch();
  const updateSortValue = (sortOption) => {
    dispatch(updateSortByIpc({ touched: true, option: sortOption }));
  };

  const getCheckedStatus = (filterName) => {
    return filterSelectionValue.find((item) => item === filterName) !== undefined;
  };

  useEffect(() => {
    if (isActiveTab === null && sortedArray.length > 0) setActiveTab(sortedArray[0].attributeId);
  }, [sortedArray]);

  useEffect(() => {
    setFilterSelectionValue(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    setSortedArray(filters);
  }, [filters]);

  const renderItem = ({ item }) => (
    <>
      {item.attributeId === isActiveTab &&
        item.options?.map((subitem, key) => {
          return (
            <TouchableHighlight
              underlayColor="none"
              key={`sub-${subitem.valueId}`}
              onPress={() => {
                onApplyFilterClick(subitem.valueId);
              }}
            >
              <Box style={styles.customCheckbox}>
                {getCheckedStatus(subitem.valueId) ? (
                  <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box" />} />
                ) : (
                  <Icon size="6" color={brandTheme.gray} as={<MaterialIcons name="check-box-outline-blank" />} />
                )}
                <Text isTruncated maxWidth={showFilterCount ? "75%" : "100%"} pl={2}>
                  {subitem.label}
                </Text>
                {showFilterCount && (
                  <Text flex={1} pl={2}>
                    ({subitem?.productCount})
                  </Text>
                )}
              </Box>
            </TouchableHighlight>
          );
        })}
    </>
  );

  return (
    <Box>
      <Box flex={1} width="100%">
        <Heading size="md" mb={2}>
          Sort By
        </Heading>
        <Menu
          trigger={(triggerProps) => {
            return (
              <Button
                variant="outine"
                w="100%"
                _hover={brandTheme.defaultHover}
                _pressed={brandTheme.defaultPressed}
                borderWidth="1px"
                borderColor="gray.100"
                {...triggerProps}
                size="sm"
                pl={3}
                {...triggerProps}
                rightIcon={<Icon as={<MaterialIcons name="arrow-downward" />} size="5" />}
              >
                {selectedSort.touched ? selectedSort.option.label : "SORT BY"}
              </Button>
            );
          }}
        >
          <Menu.OptionGroup
            title="Sort By"
            type="radio"
            value={selectedSort.option?.value}
            onChange={(val) => {
              updateSortValue(val);
            }}
          >
            {ipcSortOptions.map((item) => (
              <Menu.ItemOption key={item.value} onPress={() => updateSortValue(item)} value={item.value}>
                {item.label}
              </Menu.ItemOption>
            ))}
          </Menu.OptionGroup>
        </Menu>

        <Divider mt={5} mb={5} />
      </Box>

      {sortedArray && (
        <>
          <Heading size="md" mb={2}>
            Filter By
          </Heading>

          {sortedArray?.map((filterItem, key) => {
            return (
              <Box mb={1} key={`${key}-${filterItem.attributeId}`}>
                <TouchableHighlight
                  underlayColor={brandTheme.light}
                  onPress={() => {
                    setActiveTab(filterItem.attributeId);
                  }}
                  style={filterItem.attributeId === isActiveTab ? styles.activeFiltertab : ""}
                >
                  <Box style={styles.customButtonUi}>
                    {camelToCapitalize(filterItem.label)}{" "}
                    <Icon
                      as={
                        <MaterialIcons
                          name={filterItem.attributeId === isActiveTab ? "keyboard-arrow-down" : "keyboard-arrow-right"}
                        />
                      }
                      size="sm"
                    />
                  </Box>
                </TouchableHighlight>

                {filterItem.attributeId === isActiveTab && (
                  <FlatList
                    w="100%"
                    maxHeight={165}
                    data={sortedArray}
                    mb={2}
                    removeClippedSubviews
                    renderItem={renderItem}
                    keyExtractor={(item) => String(`${item.attributeId}`)}
                  />
                )}
              </Box>
            );
          })}
        </>
      )}

      {(!sortedArray || sortedArray?.length <= 0) && <Text>Not filter options available.</Text>}
    </Box>
  );
};

export default memo(FiltersSidebarSQL);
