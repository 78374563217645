import React, {memo, useState, useEffect } from 'react';
import { HStack, Button, StatusBar, useColorMode, useMediaQuery, Box, ScrollView, Icon, Text, PresenceTransition } from "native-base";
import { Platform, Pressable } from "react-native"
import { MaterialIcons } from "@expo/vector-icons";

/*GrapghQL*/
import { useQuery } from "@apollo/client";
import { defaultVariables, QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES } from "../apis/graphql";

/* Store */
import { useDispatch } from 'react-redux'
import { addProductTypes } from './../store/actions/appState'

/*Common Functions & Styles*/
import styles, { brandTheme } from "../utils/styles";

/* import sub componenets */
import HamburgerMenu from "./commomAssets/HamburgerMenu";
import DesktopHeader from './commomAssets/DesktopHeader';
import MobilieStickyHeader from './commomAssets/MobilieStickyHeader';
import AppConfigModal from './commomAssets/app-config/AppConfigModal';

const Header = ({ navigation, route }) => {
  const { data } = useQuery(QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES, { variables: defaultVariables });
  const { colorMode, toggleColorMode } = useColorMode();
  const [menuToggle, setMenuToggleState] = useState(false)
  const [isMediumScreen] = useMediaQuery({ maxWidth:767 });

  const updateMenuToggleState = () => {
    setMenuToggleState(!menuToggle)
  }

  const onNavigateLink = (category) =>{
    navigation.push("product-category", { category: category.slug, id: category.id })
  }

  const getActive = (link) => {
    return route?.params?.category === link.slug || false
  }

  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
      if(data && mounted){
        dispatch(addProductTypes(data?.productTypes?.results))
      }
      return () => {
        mounted = false;
      }
  }, [data])

  return (
    <>    
        <PresenceTransition safeAreaTop style={{position:"absolute", zIndex:1000, width:"100%", height: "100%", backgroundColor: brandTheme.light }} visible={menuToggle}
        initial={{ opacity: 0, transition: {   duration: 250, }, }}
        animate={{ opacity: 1, transition: {   duration: 250, },}}>
            <HamburgerMenu navigation={navigation} categories={data?.categories} colorMode={colorMode} updateMenuToggleState={updateMenuToggleState}/>
        </PresenceTransition>

        <StatusBar backgroundColor={brandTheme.brandColor} barStyle="dark-content" />

        <Box safeAreaTop backgroundColor={brandTheme.white} >
          {!isMediumScreen && (Platform.OS === 'web')  && 
            <DesktopHeader data={data} navigation={navigation} updateMenuToggleState={updateMenuToggleState} colorMode={colorMode}/>
          }

         { (isMediumScreen || (Platform.OS !== 'web')) && 
          <MobilieStickyHeader route={route} navigation={navigation} isMediumScreen={isMediumScreen} colorMode={colorMode}/>
         }

          {/* Dektop SubMenu */}
          { !isMediumScreen && 
            <HStack px={3} py={0} bg={colorMode+'.100'} borderBottomWidth="1px" borderBottomColor={colorMode+'.200'} justifyContent="space-between" minWidth="100%" flexWrap="nowrap" overflow={Platform.OS === "web"?"auto":"scroll"}>
              <HStack space={4} alignItems="flex-start">
                {data?.categories?.results?.map((category, key) => {
                    return (
                    <Button size="md" style={getActive(category)?styles.activeTab:''} variant="ghost" rounded="0" colorScheme="primary" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} key={key} onPress={() => onNavigateLink(category)}
                    >
                      {category.name.toUpperCase()}
                    </Button>
                    );
                })}
              </HStack>
              <HStack space={4} alignItems="flex-end">
                  <Button size="md" variant="ghost" rounded="0" colorScheme="primary"_hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} onPress={() => alert('Shipping & Returns')}
                  >{('Shipping & Returns').toUpperCase()}</Button>
              </HStack>
            </HStack>
          }

          { isMediumScreen && route.name === "home" && 
            <ScrollView horizontal={true} minWidth="100%" bg={colorMode+'.100'} borderBottomWidth="1px" borderBottomColor={colorMode+'.200'} >
              <HStack width="100%" px={3} py={0} justifyContent="center" flexWrap="nowrap" space={4}>
                  {data?.categories?.results?.map((category, key) => {
                      return (
                      <Pressable key={key} onPress={() => onNavigateLink(category)}>
                        <Box p={2} alignItems="center">
                          <Icon size="6" color={ colorMode+'.500' } as={<MaterialIcons name="circle" />}/>
                          <Text color= {brandTheme.dark}>{category.name.toUpperCase()}</Text>
                        </Box>
                      </Pressable>
                      );
                  })}
                  <Pressable onPress={() => alert('Shipping & Returns')}>
                    <Box p={2} alignItems="center">
                      <Icon size="6" color={ colorMode+'.500' } as={<MaterialIcons name="circle" />}/>
                      <Text color= {brandTheme.dark}>{('Shipping & Returns').toUpperCase()}</Text>
                    </Box>
                  </Pressable>
              </HStack>
            </ScrollView>
          }
        </Box>
        
        <AppConfigModal/>
    </>
  );
}

export default memo(Header);
