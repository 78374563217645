import React, { memo, useState, useEffect } from 'react';
import { HStack, Button, Icon, Box, Image, HamburgerIcon, Badge} from "native-base";
import { Pressable } from "react-native"
import { MaterialIcons } from "@expo/vector-icons";

/* Store */
import { useSelector } from 'react-redux'

/*Common Functions & Styles*/
import { brandTheme } from "../../utils/styles";
// import { defaultVariables, QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES } from "../../apis/graphql";

/* Import Images */
import LogoDeskoptImage from "../../../assets/NvizionSolutions-Logo-small.png"
import LogoMobileImage from "../../../assets/NvizionSolutions-Logo-icon.png"

/* import sub componenets */
import FlagMenu from "./flagComponent/FlagMenu";
import SearchBox from './SearchBox';

const DesktopHeader = ({ data, navigation, updateMenuToggleState, colorMode }) => {
  const [ showLoading, setShowLoading ] = useState(false);

  const cartItems = useSelector(state => state.appData.cartItems)

  const onMenuToggleFunction = () => {
    updateMenuToggleState(true)
  }

  useEffect(() => {
    setShowLoading(false)
  }, [data])

  useEffect(()=>{
    return () => { data = null };
  })

  return (
    <>
      {!showLoading && 
        <Box safeAreaTop backgroundColor={brandTheme.white} >
            <HStack bg={ brandTheme.white } borderBottomWidth="1px" borderBottomColor={colorMode+'.200'} px={3} py={3} justifyContent="space-between" alignItems="center">
              <HStack space={4} alignItems="center" width="175px">
                <Pressable variant="ghost" style={{ height: 50 }} onPress={() => navigation.navigate("home")}>
                  <Image
                    style={{ height: 50 }}
                    width='175px'
                    source={ LogoDeskoptImage }
                    alt="Logo"
                  />
                </Pressable>
              </HStack>
              <HStack space={1} alignItems="center" width={['50%', '50%', '35%', "300px"]}>
                <Button onPress={()=>onMenuToggleFunction()} height="50px" w="50px" bg={ brandTheme.menuColor } _hover={ brandTheme.menuHover} _pressed={brandTheme.menuPressed} >
                  <HamburgerIcon color={colorMode+'.100'}/>
                </Button>
                <SearchBox navigation={navigation}  colorMode={colorMode}/>
              </HStack>
              <HStack space={2} width={['50%', '172px', 'auto']}>
                <FlagMenu/>
                <Button variant="outline" colorScheme="default" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} size="sm" borderColor={colorMode+'.100'} leftIcon={<Icon as={<MaterialIcons name="account-circle" />} size="6" />}>Login</Button>
                <Button variant="outline" position="relative" colorScheme="default" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed} size="sm" borderColor={colorMode+'.100'} leftIcon={<Icon as={<MaterialIcons name="shopping-cart" />} size="6" />}>
                  Cart
                  {cartItems.length > 0 && <Badge colorScheme="secondary" position="absolute" left="-27" px={2} top="-18" zIndex={2} fontWeight="bold" rounded={20}>{ cartItems.length }</Badge>}
                </Button>
              </HStack>
            </HStack>
        </Box>
      }
    </>
  );
}

export default memo(DesktopHeader);
