import React, { memo, useState, useEffect } from 'react';
import { HStack, Button, IconButton, Icon, Text, Input, Modal, FlatList, Box, Divider, Heading, Spinner, Center, Alert } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

/* Store */
import { useSelector } from 'react-redux'

/*Common Functions & Styles*/
import { formatNametoUrl, getStatusColor} from "../../utils/commonFunctions"
import { brandTheme } from "../../utils/styles";

/*GrapghQL*/
import { useLazyQuery } from "@apollo/client";
import { defaultVariables, TYPEAHEAD_SEARCH } from "../../apis/graphql";

/* IPC */
import { IPC_CONFIG } from '../../../config';
import { init } from "../../ipc/ipc";

const QueryManager = init(IPC_CONFIG)
const defaultResults = { categories: [], brands: [], products: [] };

const SearchBox = ({navigation, colorMode, isMediumScreen}) => {
  const queryVariables = defaultVariables;  
  const [loadData, { data }] =  useLazyQuery(TYPEAHEAD_SEARCH, { variables: defaultVariables, fetchPolicy: "no-cache",nextFetchPolicy: "no-cache" });
  
  const [searchService, setSearchService] = useState(null);
  const [placement, setPlacement] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [ showLoading, setShowLoading ] = useState(false);
  const [value, setValue] = useState("");
  const [ctValue, setCTValue] = useState("");
  const [results, setResults] = useState(defaultResults);
  const [duration, setDuration] = useState(null);

  const enableIPCSearch = useSelector(state => state.appData.viewConfig.enableIPCSearch)
  const showResponseTime = useSelector(state => state.appData.viewConfig.showResponseTime)

  const openModal = (placement) => {
    setOpen(true)
    setPlacement(placement)
  }

  const initialRef = React.useRef(null)

  const resultItemClick = (item,type) => {
    setOpen(false)
    setCTValue("")
    setValue("")

    if(item.type == null || item.type == undefined){
      Object.assign(item, {type:type})
    }

    if(item.type === 0){
      const plpUrl = enableIPCSearch?"product-category-ipc":"product-category"
      navigation.push(plpUrl, { category: item.category, id: item.id })
    }
    else if(item.type === 1){
      alert('You clicked Brand - ',item.name);
    }
    else{
      navigation.push("product-detail", {category: item.category, name: formatNametoUrl(item.name), id:item.uuid || item.id })
      //navigation.navigate("product-detail", {category: item.category, name: formatNametoUrl(item.name), id:item.uuid || item.id })
    }
  }

  const updateSearchResults = (value) => {
    if(enableIPCSearch){
      setValue(value)
    }
    else{
      setResults(null)
      setCTValue(value)
      loadData({ variables: { ...queryVariables, text: value }})
    }
  }

  const onSearch = () => {
    let searchText;
    !value? (searchText = enableIPCSearch?value:ctValue) : searchText = value
    if(enableIPCSearch){
      setValue(searchText)
    }
    else{
      setResults(null)
      setCTValue(searchText)
      loadData({ variables: { ...queryVariables, text: searchText }})
    }
  }

  useEffect(()=>{
    if(!data){loadData({ variables: { ...queryVariables, text: value }})}
  },[])

  useEffect(()=>{
    if(data && !enableIPCSearch){ 
      setDuration(`${data?.responsetime} ms`)
    }
  },[data, results])

  /* SQL Queries logic */
  useEffect(() => {
    const searchService = QueryManager.search();
    const subscription = searchService.out.subscribe(({ results, timeTaken }) => {
        setResults(results)
        setDuration(timeTaken);
      });
    setSearchService(searchService);

    return () => {
      subscription.unsubscribe();
      searchService.destroy();
      setSearchService(null);
    };
  }, []);

  useEffect(() => {
    if (searchService === null) return;
    setDuration(null);
    if (value && value.trim().length > 0) {
      searchService.next(value);
    } else {
      searchService.ignoreNext();
      setResults(defaultResults);
    }
  }, [value]);
  /* SQL Queries logic ends */

  return (
    <>
    <Button colorScheme="default" variant="outline" height="50px" direction="column" bg={colorMode+'.50'} borderWidth="1px" borderColor="gray.100" width={['100%', '100%', '200px', '100%']} py="0" onPress={() => openModal("top")} justifyContent="flex-start" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
        leftIcon={<Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="search" />}/>} >Search</Button>
    {/* SearchBox */}
    <Modal isOpen={open} onClose={() => setOpen(false)} mt={0} initialFocusRef={initialRef}>
        <Modal.Content width={['full', 'full', '50%', '50%']} rounded="0" height={['full', 'full', '80%', '80%']} maxHeight="100%" maxWidth="100%" marginBottom="auto" marginTop={['0', '0', '50px', '50px']}>
          <Modal.Header>
            <HStack space={2} width="100%">
                    <Input ref={initialRef} flex="1" w="100%" placeholder="Search..." height="50px" bg={colorMode+'.50'} rounded="0" py="0" px="0" fontSize="md" onSubmitEditing={() => onSearch()}
                    value={enableIPCSearch?value:ctValue} onChange={(event) => updateSearchResults(event.nativeEvent.text)}
                    InputRightElement={
                    <IconButton size="md" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
                        onPress = { ()=> onSearch()}
                        icon={<>{!isMediumScreen && <Icon
                          size="8"
                          color={ brandTheme.inputIcon }
                          as={<MaterialIcons name="search" />}
                        />}</>}
                      />
                    }
                    InputLeftElement={
                      <IconButton size="md" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
                        onPress = { ()=> onSearch()}
                        icon={<>{isMediumScreen && <Icon
                          size="8"
                          color={ brandTheme.inputIcon }
                          as={<MaterialIcons name="search" />}
                        />}</>}
                      />
                    }
                  />

              <IconButton size="md" _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}
                    onPress={() => {
                      setOpen(false)
                    }}
                    icon={<>{<Icon
                      size="8"
                      color={ brandTheme.inputIcon }
                      as={<MaterialIcons name="close" />}
                    />}</>}
                  />
            </HStack>
            </Modal.Header>
          <Modal.Body>
            { showLoading && 
              <Center flex={1} mt="10">
                <Spinner size="lg" accessibilityLabel="Loading..." />
              </Center>
            }

            {/* show Response Time starts */}
            {duration && showResponseTime && <Alert p={1} w="100%" status={getStatusColor(duration)}>
              <HStack space={2} flexShrink={1} alignItems="center">
                  <Alert.Icon/>
                  <Text fontSize={11}>Last Response Time:</Text>
                  <Text fontSize="md">{duration}</Text>
              </HStack>
            </Alert>}
            {/* {duration && <Text>{duration}</Text>} */}

            { enableIPCSearch && <>
                        
              {results?.categories?.length>0 && <>
                <Heading mb={2} size="md">Categories</Heading>
                <FlatList
                  data={results.categories}
                  renderItem={({ item }) => (
                    <Box key={ item.uuid }>
                      <Button alignItems="stretch" size="lg" justifyContent="flex-start" rounded="0" variant='gost' width='100%'  onPress={() => resultItemClick(item)} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>{ item.name }</Button>
                      <Divider/>
                    </Box>
                  )} 
                />
              </>}

              {results?.brands?.length>0 && <>
                <Heading mb={2} mt={4} size="md">Brands</Heading>
                <FlatList
                  data={results.brands}
                  renderItem={({ item }) => (
                    <Box key={ item.uuid }>
                      <Button alignItems="stretch" size="lg" justifyContent="flex-start" rounded="0" variant='gost' width='100%'  onPress={() => resultItemClick(item)} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>{ item.name }</Button>
                      <Divider/>
                    </Box>
                  )} 
                />
              </>}

              {results?.products?.length>0 && <>
                <Heading mb={2} mt={4} size="md">Products</Heading>
                <FlatList
                  data={results.products}
                  renderItem={({ item }) => (
                    <Box key={ item.uuid }>
                      <Button alignItems="stretch" size="lg" justifyContent="flex-start" rounded="0" variant='gost' width='100%'  onPress={() => resultItemClick(item)} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>{ item.name }</Button>
                      <Divider/>
                    </Box>
                  )} 
                />
              </>}
            </>}

            { !enableIPCSearch && <>
              {data?.categorySearch?.results?.length>0 && <>
                <Heading mb={2} mt={4} size="md">Categories</Heading>
                <FlatList
                  data={data?.categorySearch?.results}
                  renderItem={({ item }) => (
                    <Box key={ item.id }>
                      <Button alignItems="stretch" size="lg" justifyContent="flex-start" rounded="0" variant='gost' width='100%'  onPress={() => resultItemClick(item,0)} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>{ item.name }</Button>
                      <Divider/>
                    </Box>
                  )} 
                />
              </>}

              {data?.productProjectionSearch?.results?.length>0 && <>
                <Heading mb={2} mt={4} size="md">Products</Heading>
                <FlatList
                  data={data?.productProjectionSearch?.results}
                  renderItem={({ item }) => (
                    <Box key={ item.id }>
                      <Button alignItems="stretch" size="lg" justifyContent="flex-start" rounded="0" variant='gost' width='100%'  onPress={() => resultItemClick(item,null)} _hover={ brandTheme.defaultHover} _pressed={brandTheme.defaultPressed}>{ item.name }</Button>
                      <Divider/>
                    </Box>
                  )} 
                />
              </>}

              {data?.categorySearch?.results?.length===0 && data?.productProjectionSearch?.results?.length ===0 && 
              <Text>Enter relevant keyword to see the results</Text>}
            </>}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default memo(SearchBox);
