import React, {memo, useState, useEffect } from 'react';
import { HStack, Icon, Box, useColorMode, useMediaQuery, Badge, Pressable, PresenceTransition} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

/* Store */
import { useSelector } from 'react-redux'

/* Import GraphQl */
import { useQuery } from "@apollo/client";
import { defaultVariables, QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES } from "../apis/graphql";

/*Common Functions & Styles*/
import styles, { brandTheme } from "../utils/styles";

/* Import sub componenets */
import HamburgerMenu from "./commomAssets/HamburgerMenu";

const MobilieStickyNav = ({ navigation,route }) => {
  const { data } = useQuery(QUERY_GET_CATEGORIES_WITH_PRODUCT_TYPES, { variables: defaultVariables });
  const { colorMode, toggleColorMode } = useColorMode();
  const [isOpen, setIsOpen] = useState(false)
  const [ showLoading, setShowLoading ] = useState(false);
  const [menuToggle, setMenuToggleState] = useState(false)
  const [isMediumScreen] = useMediaQuery({ maxWidth:767 });

  const cartItems = useSelector(state => state.appData.cartItems)

  const onMenuToggleFunction = () => {
    setMenuToggleState(!menuToggle)
  }

  const updateMenuToggleState = () => {
    setMenuToggleState(!menuToggle)
  }

  const getActive = (link) => {
    return route.name === link
  }

  useEffect(() => {
    let mounted = true;
    if(data && mounted){
      setShowLoading(false)
    }
    return () => {
      mounted = false;
    }
  }, [data])

  return (
    <>
      {!showLoading && isMediumScreen && <>
          <PresenceTransition style={{position:"absolute", width:300, height:"100%", right:0 }} visible={menuToggle}
          initial={{ opacity: 0, translateX: 100, transition: {   duration: 250, }, }}
          animate={{ opacity: 1, translateX: 0, transition: {   duration: 250, },}}>
              <HamburgerMenu navigation={navigation} categories={data?.categories} colorMode={colorMode} updateMenuToggleState={updateMenuToggleState}/>
        </PresenceTransition>

        <Box backgroundColor={brandTheme.white}>
          <HStack bg={ brandTheme.light } borderBottomWidth="1px" borderBottomColor={colorMode+'.200'} px={0} py={0}  >
            <Pressable w="25%" style={getActive('home')?styles.activeTab:''} onPress={() => navigation.navigate("home")}>
              <Box p={2} alignItems="center">
                <Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="home" />}/>
              </Box>
            </Pressable>

            <Pressable w="25%" style={getActive('account')?styles.activeTab:''} onPress = { ()=> alert('My Account')}>
              <Box p={2} alignItems="center">
                <Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="account-circle" />}/>
              </Box>
            </Pressable>

            <Pressable w="25%" style={getActive('shopping-cart')?styles.activeTab:''} onPress={() => alert('Cart')}>
              <Box p={2} alignItems="center">
              {cartItems.length > 0 && <Badge colorScheme="secondary" position="absolute" px={2} top="-2" zIndex={2} fontWeight="bold" rounded={20}>{ cartItems.length }</Badge>}
                <Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="shopping-cart" />}/>
              </Box>
            </Pressable>

            <Pressable w="25%" style={getActive('cart')?styles.activeTab:''} onPress={()=> onMenuToggleFunction()}>
              <Box p={2} alignItems="center">
                <Icon size="8" color={ brandTheme.inputIcon } as={<MaterialIcons name="menu" />}/>
              </Box>
            </Pressable>
          </HStack>
        </Box>
          
        {/* Menu */ }
        {/* { menuToggle && 
          <HamburgerMenu position="relative" navigation={navigation} categories={data.categories} colorMode={colorMode} updateMenuToggleState={updateMenuToggleState}/>
        } */}
        </>
      }
    </>
  );
}

export default memo(MobilieStickyNav);
